import React from 'react';
import PropTypes from 'prop-types';
import { GatsbyImage } from 'gatsby-plugin-image';

import { RichTextField } from '../PrismicFields';
import { Cluster, Switcher } from '../../EveryLayout';

const InfoBoxes = ({ slice, colour }) => {
  if (slice.primary.image?.thumbnails?.Landscape?.gatsbyImageData && slice.items?.length > 0) {
    return (
      <Cluster className={`slice-${slice.slice_type} items-start gap-4`}>
        <div>
          <figure className="sm:max-w-[340px] md:max-w-[400px] lg:max-w-[600px] xl:max-w-[800px] mx-auto">
            <GatsbyImage
              image={slice.primary.image.thumbnails.Landscape.gatsbyImageData}
              alt={slice.primary.image.alt || ''}
              objectFit="contain"
            />
          </figure>
        </div>
        <div className="h-full">
          <div className={`inline-block bg-transparent p-2 pb-[20%] border border-dashed border-${colour} text-${colour} sm:max-w-[200px] md:max-w-[300px] lg:max-w-[300px] xl:max-w-[400px] xl:h-full`}>
            <span className="block font-numbers leading-none text-7xl mb-3 leading-[0.8]">
              {slice.items[0].datapoint}
            </span>
            <RichTextField as="span" data={slice.items[0].summary} className="block text-lg" />
            <RichTextField as="span" data={slice.items[0].footnote} className="block text-sm mt-8" />
          </div>
        </div>
      </Cluster>
    );
  }
  else if (slice.items?.length > 0) {
    return (
      <div className={`slice-${slice.slice_type} _switcher mx-auto`}>
        {slice.items.map((item, i) => {
          if (item.datapoint) {
            return (
              <div className="flex" key={`${slice.slice_type}-${item.datapoint}-${i}`}>
                <div className={`mx-auto inline-block bg-transparent p-2 pb-[20%] border border-dashed border-${colour} text-${colour} max-w-[400px] sm:h-full`}>
                  <span className="block font-numbers leading-none text-7xl mb-3 leading-[0.8]">
                    {item.datapoint}
                  </span>
                  <RichTextField as="span" data={item.summary} className="block text-lg" />
                  <RichTextField as="span" data={item.footnote} className="block text-sm mt-8" />
                </div>
              </div>
            );
          }
          return null;
        })}
      </div>
    );
  }
};

InfoBoxes.propTypes = {
  slice: PropTypes.shape({}).isRequired,
  colour: PropTypes.string,
};


export default InfoBoxes;
