import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import { LogoMonogramBlack } from '../Brand';
import { Stack } from '../EveryLayout';

import { headerClean } from '../../helpers';

const TeaserStoryWrapper = ({ children }) => (
  <Stack>
    {children}
  </Stack>
);

const TeaserStory = ({ node }) => {
  const headline = headerClean(node.data.title.text);

  let mainImage = null;
  if (node.data.main_image?.gatsbyImageData) {
    mainImage = node.data.main_image;
  }
  else {
    if (node.data.featured_image?.gatsbyImageData) {
      mainImage = node.data.featured_image;
    }
  }

  return (
    <TeaserStoryWrapper>
      {mainImage?.gatsbyImageData
        ? (
        <Link
          to={`/impact/meet-our-clients/${node.uid}/`}
          className="block flex mx-auto"
          aria-label={node.data.title.text}
        >
          <GatsbyImage
            image={mainImage.gatsbyImageData}
            alt=""
            objectFit="contain"
          />
        </Link>
      ) : (
        <div className="bg-nordic-blue-500 px-12 py-10 2xl:px-24 2xl:py-20">
          <div className="aspect-w-3 aspect-h-4">
            <LogoMonogramBlack className="max-w-[8rem] mx-auto flex items-center" />
          </div>
        </div>
      )}
      <div>
      {mainImage?.gatsbyImageData
        ? (
        <h3>
          {/* 21px > 40px, lh 25px > 46px */}
          <span
            className="text-[1.3rem] sm:text-sm 2xl:text-[2.5rem] 2xl:leading-[1.15]"
            dangerouslySetInnerHTML={{ __html: headline }}
          />
        </h3>
      ) : (
        <h3>
          {/* 21px > 40px, lh 25px > 46px */}
          <Link
            to={`/impact/meet-our-clients/${node.uid}/`}
            className="no-underline"
          >
            <span
              className="text-[1.3rem] sm:text-sm 2xl:text-[2.5rem] 2xl:leading-[1.15]"
              dangerouslySetInnerHTML={{ __html: headline }}
            />
          </Link>
        </h3>
      )}
        <Link
          to={`/impact/meet-our-clients/${node.uid}/`}
          className="block text-sm mt-4"
          aria-hidden="true"
          tabIndex="-1"
        >
          Read more
        </Link>
      </div>
    </TeaserStoryWrapper>
  )
};

TeaserStoryWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

TeaserStory.propTypes = {
  node: PropTypes.shape({}).isRequired,
};

export default TeaserStory;
