import React from 'react';
import PropTypes from 'prop-types';

const Columns = ({ children, className = '' }) => (
  <div className={`columns-3xs gap-4 ${className}`}>
    {children}
  </div>
);

Columns.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export default Columns;
