import React from 'react';
import { Link } from 'gatsby';

import { LogoMonogramBlack } from '../Brand';
import { Cluster } from '../EveryLayout';
import { MainMenu } from '../Page';

import { menu } from '../../data';

const Header = () => {
  return (
    <div className="w-full">
      <div className="z-10 relative bg-nordic-blue-500">
        <div className="px-4 py-2">
          <Cluster className="justify-between">
            <Link
              to="/"
              className="block py-2 w-[4rem]"
            >
              <LogoMonogramBlack className="w-[4rem]" alt="Return to homepage" />
            </Link>
          </Cluster>
        </div>
      </div>
      <div className="z-0">
        <MainMenu data={menu} />
      </div>
  </div>
  );
};

export default Header;
