import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { LazyMotion } from 'framer-motion';

import {
  Footer,
  Header,
} from './Layout';
import { FooterCTA } from './Page';

import '../styles/base.css';
import '../styles/fluid.css';
import '../styles/extend.css';

const loadFeatures = () =>
  import('../helpers/framer-features.js').then(res => res.default);

const Layout = ({ children, location }) => {
  return (
    <LazyMotion strict features={loadFeatures}>
      <header id="header" className="sticky top-0 z-50">
        <Header />
      </header>
      <main id="main">
        {children}
      </main>
      <FooterCTA />
      <footer id="footer" className="bg-gray-50" aria-labelledby="footer-heading">
        <h2 id="footer-heading" className="sr-only">
          Footer
        </h2>
        <Footer />
      </footer>
    </LazyMotion>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.shape({
    href: PropTypes.string,
  }).isRequired,
};

export default Layout;
