import React from 'react';
import {
  useStaticQuery,
  graphql
} from 'gatsby';

const usePrismicCompanyOwners = () => {
  const { allPrismicCompany } = useStaticQuery(
    graphql`
      query {
        allPrismicCompany (
          sort: {
            data: {
              title: {
                text: ASC
              }
            }
          }
          filter: {
            data: {
              type: {
                eq: "investor/owner"
              }
            }
          }
        ) {
          edges {
            node {
              id
              uid
              data {
                title {
                  text
                }
                website {
                  url
                }
                logo {
                  gatsbyImageData(
                    width: 180
                    breakpoints: [361, 481, 737, 981, 1281, 1681]
                  )
                }
              }
            }
          }
        }
      }
    `
  );

  return {
    companies: allPrismicCompany.edges,
  };
};

export default usePrismicCompanyOwners;