import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import {
  Disclosure,
  Transition
} from '@headlessui/react';
import {
  PlusIcon,
  ArrowTopRightOnSquareIcon
} from '@heroicons/react/24/solid';

import { Cluster, Stack } from '../../EveryLayout';
import { FadeInOpacity } from '../../Misc/FadeIn';

import { prettyUrl } from '../../../helpers';

const ViewInvestees = (props) => {
  const { data } = props;
  return (
    <div className="flex flex-wrap gap-2 lg:gap-6">
      {data.edges.map(({ node }, i) => {
        const websiteLabel = node.data.website?.url ? prettyUrl(node.data.website.url) : null;

        /* Clean out empty story objects in story array */
        if (node.data.crel?.length > 0) {
          node.data.crel = node.data.crel.filter(({ story }) => story !== null);
        }

        return (
          <Cluster
            key={`inv-${node.id}-${i}`}
            className="w-full gap-6 lg:flex-nowrap items-start"
          >
            <FadeInOpacity className="grow lg:grow-0 mt-6 flex justify-center sm:justify-start">
              <GatsbyImage
                image={node.data.logo.gatsbyImageData}
                alt={node.data.logo.alt || ''}
                objectFit="contain"
                className="mx-auto sm:mx-0"
              />
            </FadeInOpacity>
            <Disclosure
              key={`investor-${node.id}`}
              className="w-full lg:grow bg-off-white px-4 pt-6 pb-12 lg:px-6 2xl:px-8 2xl:pt-8"
            >
              <FadeInOpacity>
                <Stack className="">
                  {node.data?.title?.text
                    && (
                    <Cluster className="justify-between items-start flex-nowrap">
                      <div className="prose prose-custom">
                        <h3 className="text-base">{node.data.title.text}</h3>
                      </div>
                      {node.data.body?.html
                        && (
                        <Disclosure.Button aria-label={`Toggle details for ${node.data.title.text}`}>
                          <PlusIcon className="h-6 w-6" aria-hidden="true" />
                        </Disclosure.Button>
                      )}
                    </Cluster>
                  )}
                  <Transition
                    enter="transition duration-100 ease-out"
                    enterFrom="transform scale-95 opacity-0"
                    enterTo="transform scale-100 opacity-100"
                    leave="transition duration-75 ease-out"
                    leaveFrom="transform scale-100 opacity-100"
                    leaveTo="transform scale-95 opacity-0"
                    className=""
                  >
                    <Disclosure.Panel id={node.prismicId}>
                      <Stack>
                        <div>
                          <div className="prose prose-custom">
                            {node.data.body?.html
                              && (
                              <span className="text-sm" dangerouslySetInnerHTML={{ __html: node.data.body.html }} />
                            )}
                          </div>
                        </div>
                      </Stack>
                    </Disclosure.Panel>
                  </Transition>
                  <div className="prose prose-custom">
                    {node.data.crel?.length > 0
                      && (
                      <div className="">
                        <h4 className="mt-4 font-trade text-[1rem] sm:text-xs 2xl:text-[1.3rem] leading-[1.4] 2xl:leading-[1.2]">Client Stories</h4>
                        <ul className="mb-0">
                        {node.data.crel.map(({ story }, i) => {
                          if (story === null) {
                            return null;
                          }
                          if (story.document?.data) {
                            return (
                              <li
                                className="ml-0 pl-0"
                                key={`inv-${story.id}-${i}`}
                              >
                                <Link
                                  to={`/impact/meet-our-clients/${story.document.uid}`}
                                >
                                  {`${story.document.data.title.text}`}
                                </Link>
                              </li>
                            )
                          }
                        })}
                        </ul>
                      </div>
                    )}
                  </div>
                  <div className="">
                    <div className="prose prose-custom">
                      {node.data.website?.url
                        && (
                        <div className="text-sm flex items-center">
                          <a href={node.data.website.url} target="_blank" rel="nofollow">{websiteLabel}</a><ArrowTopRightOnSquareIcon className="color-soft-black ml-1 h-5 w-5" aria-hidden="true" />
                        </div>
                      )}
                    </div>
                  </div>
                </Stack>
              </FadeInOpacity>
            </Disclosure>
          </Cluster>
        )
      })}
    </div>
  )
};

export default ViewInvestees;
