/**
 * SEO component.
 *
 * Most sites need basic meta tags for SEO so I wanted to provide a simple
 * component BUT I also don't want to reinvent the wheel. This one is
 * essentially a copy of the component in Gatsby's official default starter.
 *
 * See: https://github.com/gatsbyjs/gatsby-starter-default/blob/master/src/components/seo.js
 */

import * as React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';

function Seo({
  description,
  title,
  image,
  path,
  children
}) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
          }
        }
      }
    `
  );

  const metaDescription = description || site.siteMetadata.description;
  const defaultTitle = site.siteMetadata?.title;

  // Todo: Add new default image to static/media folder then add URL here.
  const defaultImage = `${site.siteMetadata.siteUrl}/media/r-r-Juhudi-8-cropped.jpg`;

  return (
    <>
      <title>{defaultTitle ? `${title} | ${defaultTitle}` : title}</title>
      <meta id="_description" name="description" content={metaDescription} />
      <meta id="_og-title" property="og:title" content={title} />
      <meta id="_og-description" property="og:description" content={metaDescription} />
      <meta id="_og-type" property="og:type" content="website" />
      <meta id="_twitter-card" name="twitter:card" content="summary_large_image" />
      <meta id="_twitter-creator" name="twitter:creator" content="@Abler_Nordic" />
      <meta id="_twitter-title" name="twitter:title" content={title} />
      <meta id="_twitter-description" name="twitter:description" content={metaDescription} />
      <meta id="_twitter-site" name="twitter:site" content="@Abler_Nordic" />
      {site.siteMetadata.siteUrl
        && (
          <>
            <meta id="_og-url" property="og:url" content={`${site.siteMetadata.siteUrl}${path}`} />
            <meta id="_twitter-url" property="twitter:url" content={`${site.siteMetadata.siteUrl}${path}`} />
          </>
        )
      }
      <meta id="_og-image" property="og:image" content={image || defaultImage} />
      <meta id="_twitter-image" name="twitter:image" content={image || defaultImage} />
      {children}
    </>
  );
}

Seo.propTypes = {
  description: PropTypes.string,
  title: PropTypes.string,
  image: PropTypes.string,
  path: PropTypes.string,
  children: PropTypes.node,
};

Seo.defaultProps = {
  path: '',
};

export default Seo;
