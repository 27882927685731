import React from 'react';

const PrismicSliceBlockquote = ({ slice }) => {
  return (
    <div className="prose prose-custom mx-auto italic border-nordic-blue-500 pl-4 sm:pl-6">
      <blockquote>
        <span dangerouslySetInnerHTML={{ __html: slice.primary.text.html }} />
      </blockquote>
    </div>
  );
};

export default PrismicSliceBlockquote;
