import React from 'react';
import PropTypes from 'prop-types';
import {
  Disclosure,
  Transition
} from '@headlessui/react';
import { PlusIcon } from '@heroicons/react/24/solid';

import {
  Cluster,
  Stack
} from '../EveryLayout';

const Person = ({ node }) => (
  <Disclosure>
    <div>
      <Stack className="bg-off-white px-4 pt-6 pb-12 lg:px-6 2xl:px-8 2xl:pt-8">
        {node.data?.name
          && (
          <Cluster className="justify-between flex-nowrap">
            <div className="">
              <div className="prose prose-custom">
                <h3 className="font-trade text-base tracking-tight">{node.data.name.text}</h3>
              </div>
            </div>
            <Disclosure.Button aria-label={`Toggle details for ${node.data.name.text}`}>
              <PlusIcon className="h-6 w-6" aria-hidden="true" />
            </Disclosure.Button>
          </Cluster>
        )}
        {node.data?.title
          && (
          <div className="prose prose-custom">
            <p>{node.data.title}</p>
          </div>
        )}
        {node.data?.body
          && (
          <Transition
            enter="transition duration-100 ease-out"
            enterFrom="transform scale-95 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition duration-75 ease-out"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-95 opacity-0"
            className=""
          >
            <Disclosure.Panel id={node.prismicId}>
              <div className="prose prose-custom">
                <span className="text-sm" dangerouslySetInnerHTML={{ __html: node.data.body.html }} />
              </div>
            </Disclosure.Panel>
          </Transition>
        )}
      </Stack>
    </div>
  </Disclosure>
);

export default Person;
