import React from 'react';
import PropTypes from 'prop-types';

import { RichTextField } from '../PrismicFields';

const Blockquote = ({ slice, colour }) => {
  return (
    <div className={`slice-${slice.slice_type} prose prose-custom mx-auto`}>
      <blockquote className={`border-${colour}`}>
        <RichTextField as="span" data={slice.primary.text} />
      </blockquote>
    </div>
  )
};

Blockquote.propTypes = {
  slice: PropTypes.shape({}).isRequired,
  colour: PropTypes.string,
};

export default Blockquote;
