import React from 'react';
import { PlayIcon } from '@heroicons/react/24/solid';
import clip from 'text-clipper';

import { BadgeTiny } from './';

const WatchThumbnail = ({ video, handleOnClick }) => {
  return (
    <div className="relative">
      <div className="aspect-w-16 aspect-h-9">
        <img
          src={video.data.embed?.thumbnail_url}
          alt=""
          className="object-cover"
        />
      </div>
      <div className="absolute z-10 top-0 left-0 w-full h-full flex items-center justify-center">
        <button
          onClick={() => handleOnClick(video)}
          className="rounded-full bg-[rgba(31,30,29,0.9)] p-2"
        >
          <PlayIcon className="flex-shrink-0 h-6 w-6 text-off-white" aria-hidden="true" />
        </button>
      </div>
    </div>
  );
};

const TeaserVideo = ({ video, watchVideoSetState }) => {

  const clippedHtml = video.data.description?.html
  ? clip(video.data.description?.html, 140, { html: true, maxLines: 5 })
  : '';

  return (
    <div className="_stack">
      <WatchThumbnail video={video} handleOnClick={watchVideoSetState} />
      <div>
        <h3 className="text-[1.75rem] leading-[1.215]">
          {video.data.title?.text}
        </h3>
        {clippedHtml && (
          <span
            className="block text-[1.3rem] leading-[1.25] mt-4"
            dangerouslySetInnerHTML={{ __html: clippedHtml }}
          />
        )}
      </div>
      {video.data.tag_group?.length > 0 && (
        <div>
          {video.data.tag_group.map((tagGroup, i) => {
            if (tagGroup.tag !== null) {
              return (
                <BadgeTiny to={`/videos/tag/${tagGroup.tag.document.uid}`} key={`video-teaser-${video.uid}-badge-${i}`}>
                  {tagGroup.tag.document.data.name}
                </BadgeTiny>
              );
            }
          })}
        </div>
      )}
    </div>
  );
}

export default TeaserVideo;