import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { PrismicRichText } from '@prismicio/react';

import { YouTubeEmbed } from '../Embeds';

import { useCookieWatcher } from '../../../hooks/useCookieWatcher';

const RichTextField = (props) => {

  // No consent by default. We don't care (yet) if consent checker is loaded.
  const [cookieConsent, setCookieConsent] = useState(false);

  // Watch CookieYes cookie value.
  let cookie = useCookieWatcher('cookieyes-consent', { valueOnly: true });

  useEffect(() => {
    // Only continue if consent hasn't already been given since we can't
    // revoke third-party cookies.
    if (!cookieConsent) {
      // If cookie value is present, that means the user interacted with the
      // consent checker. Check value of cookie and update state.
      if (cookie !==  null && typeof cookie !== 'undefined') {
        // Convert string to object.
        const parsedCookie = cookie.split(',').reduce((prev, current) => {
          const [name, ...value] = current.split(':');
          prev[name] = value.join(':');
          return prev;
        }, {});
        // If user has accepted advertisement cookies, set state to true.
        if (parsedCookie?.advertisement) {
          setCookieConsent(parsedCookie.advertisement === 'yes');
        }
      }
    }
  }, [cookie, cookieConsent, setCookieConsent]);

  const { data } = props;

  const attrs = {
    className: props.className ? props.className : null,
    id: props.id ? props.id : null,
  };

  const ComponentType = props.as || 'div';

  if (data.richText) {
    return (
      <ComponentType {...attrs}>
        <PrismicRichText
          field={data.richText}
          components={{
            embed: ({ node, key }) => {
              if (node.oembed.provider_name === 'YouTube') {
                return <YouTubeEmbed embed={node.oembed} consent={cookieConsent} />;
              }
              else {
                return (
                  <div
                    key={key}
                    data-oembed={node.oembed.embed_url}
                    data-oembed-type={node.oembed.type}
                    data-oembed-provider={node.oembed.provider_name}
                    dangerouslySetInnerHTML={{ __html: node.oembed.html }}
                  />
                );
              }
            }
          }}
        />
      </ComponentType>
    );
  }

  return null;
};

RichTextField.propTypes = {
  data: PropTypes.shape({
    html: PropTypes.string,
    richText: PropTypes.arrayOf(PropTypes.shape({
      type: PropTypes.string,
    })),
  }).isRequired,
};

export default RichTextField;
