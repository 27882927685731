import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';

const PrismicSliceImage = ({ slice }) => {

  const imageData = slice.primary || slice;
  if (imageData.image?.gatsbyImageData && imageData.caption?.html) {
    return (
      <div>
        <figure className="max-w-[800px] mx-auto">
          <GatsbyImage
            image={imageData.image.gatsbyImageData}
            alt={imageData.image.alt || ''}
            objectFit="contain"
          />
          <figcaption className="prose prose-custom text-sm italic mt-2" dangerouslySetInnerHTML={{ __html: imageData.caption.html }} />
        </figure>
      </div>
    )
  }
  if (imageData.image?.gatsbyImageData) {
    return (
      <div className="max-w-[800px] mx-auto">
        <GatsbyImage
          image={imageData.image.gatsbyImageData}
          alt={imageData.image.alt || ''}
          objectFit="contain"
        />
      </div>
    );
  }
  return null;
};

export default PrismicSliceImage;
