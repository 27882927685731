import React from 'react';
import PropTypes from 'prop-types';

import { ListHoverItem } from '../';

import { usePrismicCompanySupporters } from '../../../hooks';

const ViewSupporters = () => {
  const { companies } = usePrismicCompanySupporters();
  return (
    <div className="divide-y divide-color-base divide-opacity-20 border-y border-color-base border-opacity-20">
      {companies.map(({ node }, i) => (
        <React.Fragment
          key={`supporters-listing-${node.id}`}
        >
          {node.data.website
            ? (
            <a
              href={`${node.data.website.url}`}
              target="_blank"
              rel="nofollow noreferrer"
              className="block hover-row py-2 font-trade text-[1rem] sm:text-xs 2xl:text-[1.15rem] hover:bg-off-white"
              key={`companies-listing-${node.id}`}
            >
              <ListHoverItem>
                {node.data.title.text}
              </ListHoverItem>
            </a>
          ) : (
            <div className="hover-row py-2 font-trade text-[1rem] sm:text-xs 2xl:text-[1.15rem] hover:bg-off-white">
              <ListHoverItem>
                {node.data.title.text}
              </ListHoverItem>
            </div>
          )}
        </React.Fragment>
      ))}
    </div>
  )
};

export default ViewSupporters;
