import React, { useRef, useEffect, useState } from 'react'

import {
  Transition
} from '@headlessui/react';

import {
  Spinner,
  VideoButton
} from '../Page';

import Video from './Video';
// import VideoControls from './VideoControls';

import videoH265 from '../../video/AN_Loop_h265.mp4';
import videoWebm from '../../video/AN_Loop_vp9.webm';
import videoFallback from '../../video/AN_Loop_opt.mp4';

const VideoPlayer = ({ poster }) => {

  const videos = [
    {
      key: 'h265',
      src: videoH265,
      type: 'video/mp4',
    },
    {
      key: 'webm',
      src: videoWebm,
      type: 'video/webm',
    },
    {
      key: 'fallback',
      src: videoFallback,
      type: 'video/mp4',
    },
  ];

  const media = useRef(null);

  /**
   * Needed if we re-enable controls
   */
  // const play = useRef(null);
  // const stop = useRef(null);

  const [playing, setPlaying] = useState(false);

  // the 'play' event has been fired
  // there has been user interaction with the page
  const hasStarted = useRef(false);

  // the video has loaded and can play
  const canPlay = useRef(false);

  const onPlay = () => {
    hasStarted.current = true;
    setPlaying(true);
  };

  const onCanPlayThrough = () => {
    canPlay.current = true;
  };

  const onEnd = () => {
    setPlaying(false);
  };

  useEffect(() => {
    if (media) {
      media.current.addEventListener('ended', onEnd);
      media.current.addEventListener('canplaythrough', onCanPlayThrough);
      media.current.addEventListener('play', onPlay);

      // if the events have fired before the event handlers have registered
      // we may have missed them. Check readyState
      if (!hasStarted.current || !canPlay.current && media.readyState > 3) {
        onCanPlayThrough();
        onPlay();
      }

      // not needed unless we want a progress indicator
      // media.current.addEventListener('timeupdate', setTime);
    }
  }, [media]);



  useEffect(() => {
    if (hasStarted.current) {
      if (playing) {
        media.current.play();
      } else {
        media.current.pause();
      }
    }
  }, [playing]);


  const togglePlaying = (_playing) => {
    if (canPlay.current) {
      setPlaying(!_playing);
    }
  };

  return (
    <>
      <div
        className="text-white z-10 flex justify-center items-center bg-[rgba(0,0,0,0.25)]"
        onClick={() => togglePlaying(playing)}
      >
        <Transition
          enter="transition-opacity ease-linear duration-1000"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-linear duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          show={!canPlay.current}
          className="absolute"
        >
          <div
            className="no-underline inline-flex items-center text-[1.125rem] sm:text-[1.3125rem] px-3 py-2 gap-2"
          >
            <div>
              Loading
            </div>
            <div className="text-white">
              <Spinner />
            </div>
          </div>
        </Transition>

        <Transition
          enter="transition-opacity ease-linear duration-1000"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-linear duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          show={canPlay.current}
          className="absolute"
        >
          <div>
            <VideoButton>
              Watch Full Video
            </VideoButton>
          </div>
        </Transition>
      </div>

      <Video
        videos={videos}
        media={media}
        poster={poster}
        canPlay={canPlay}
        playing={playing}
        togglePlaying={togglePlaying}
      />

      {/* controls not currently needed */}
      {/*
        <VideoControls
          media={media}
          play={play}
          playing={playing}
          stop={stop}
          canPlay={canPlay}
          setPlaying={setPlaying}
        />
      */}

    </>
  );
}

export default VideoPlayer;

