import React from 'react';

import { placeholderImages } from '../../data';

const CoverWrapper = ({ children }) => (
  <section className="my-8 sm:my-24">
    <div className="max-w-9xl px-0 sm:px-6 mx-auto">
      <div className="max-w-8xl">
        {children}
      </div>
    </div>
  </section>
);

const CoverImage = () => (
  <CoverWrapper>
    <div className="aspect-w-4 aspect-h-3">
      <img src={placeholderImages[0].url} alt="" className="object-cover" />
    </div>
  </CoverWrapper>
);

export {
  CoverImage,
  CoverWrapper,
};
