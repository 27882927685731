import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

// All badge components should look the same on mobile.

const Badge = ({ children, to }) => (
  <>
    {to ? (
      <Link
        to={to}
        className="inline-block mt-1 mr-2 mb-3"
      >
        <span className="inline-flex items-center rounded-full bg-nordic-blue-300 px-[20px] sm:px-[24px] 2xl:px-[32px] pt-[9px] sm:pt-[12px] 2xl:pt-[18px] pb-[7px] sm:pb-[10px] 2xl:pb-[16px] font-trade text-[0.8rem] sm:text-[1rem] 2xl:text-[1.15rem] 2xl:leading-none">
          {children}
        </span>
      </Link>
      ) : (
      <div className="inline-block mt-1 mr-2 mb-3">
        {/* 16px > 18px, lh 22px > 18px */}
        {/* padding 12px 24px 8px 24px > 18px 32px 12px 32px */}
        <span className="inline-flex items-center rounded-full bg-nordic-blue-300 px-[20px] sm:px-[24px] 2xl:px-[32px] pt-[9px] sm:pt-[12px] 2xl:pt-[18px] pb-[7px] sm:pb-[10px] 2xl:pb-[16px] font-trade text-[0.8rem] sm:text-[1rem] 2xl:text-[1.15rem] 2xl:leading-none">
          {children}
        </span>
      </div>
    )}
  </>
);

const BadgeSmall = ({ children, to }) => (
  <>
    {to ? (
      <Link
        to={to}
        className="inline-block mt-1 mr-2 mb-2"
      >
        <span className="inline-flex items-center rounded-full bg-nordic-blue-300 px-[20px] sm:px-[24px] pt-[9px] sm:pt-[12px] pb-[7px] sm:pb-[10px] font-trade text-[0.8rem] sm:text-[1rem] leading-[1.4]">
          {children}
        </span>
      </Link>
      ) : (
      <div className="inline-block mt-1 mr-2 mb-2">
        {/* ? > 16px, lh ? 22px */}
        {/* ? > padding 12px 24px 8px 24px */}
        <span className="inline-flex items-center rounded-full bg-nordic-blue-300 px-[20px] sm:px-[24px] pt-[9px] sm:pt-[12px] pb-[7px] sm:pb-[10px] font-trade text-[0.8rem] sm:text-[1rem] leading-[1.4]">
          {children}
        </span>
      </div>
    )}
  </>
);

const BadgeTiny = (props) => {
  const { children } = props;

  const attrs = {};

  if (props.to) {
    attrs.to = props.to;
    return (
      <Link
        className="inline-block mt-1 mr-2 mb-2"
        {...attrs}
      >
        <span className="inline-flex items-center rounded-full bg-nordic-blue-300 px-[20px] pt-[9px] pb-[7px] font-trade text-[0.8rem] leading-[1.4] xl:px-[18px] xl:pt-[8px] xl:pb-[6px]">
          {children}
        </span>
      </Link>
    );
  }
  else {
    if (props.onClick) {
      attrs.onClick = props.onClick;
    }
    return (
      <button
        className="inline-block mt-1 mr-2 mb-2"
        {...attrs}
      >
        <span className="inline-flex items-center rounded-full bg-nordic-blue-300 px-[20px] pt-[9px] pb-[7px] font-trade text-[0.8rem] leading-[1.4]">
          {children}
        </span>
      </button>
    );
  }
};

Badge.propTypes = {
  children: PropTypes.node.isRequired,
  to: PropTypes.string,
};

BadgeSmall.propTypes = {
  children: PropTypes.node.isRequired,
  to: PropTypes.string,
};

BadgeTiny.propTypes = {
  children: PropTypes.node.isRequired,
  to: PropTypes.string,
};

export {
  Badge,
  BadgeSmall,
  BadgeTiny,
};
