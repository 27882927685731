import React from 'react';
import PropTypes from 'prop-types';

import { TeaserArticleRelated } from '../';

const RelatedNews = ({ data }) => {
  return (
    <div className="prose prose-custom">
      <h3 className="text-lg mt-8">Related news</h3>
      <ul className="list-none m-0 p-0">
        {data.slice(0, 4).map(({ related_content }, i) => {
          if (!related_content) return null;
          const { document } = related_content;
          return (
            <li className="" key={`related-news-${document.uid}`}>
              <TeaserArticleRelated node={document} />
            </li>
          )
        })}
      </ul>
    </div>
  );
}

RelatedNews.propTypes = {
  data: PropTypes.array.isRequired,
};

export default RelatedNews;