import React from 'react';

import {
  Blockquote,
  Image,
  ImageGrid,
  JumpLinks,
  RichText,
} from './';
import {
  Stack,
  Switcher
} from '../EveryLayout';
import { FadeInOpacity } from '../Misc/FadeIn';

const Section = ({ children, section }) => {

  const style = section.style?.replace(/\s+/g, '-').toLowerCase() || '';

  return (
    <section
      id={section.sid || null}
      className={`section ${style}${style === 'style-1' ? ' bg-gray-50 py-16' : ''}`}
    >
      <div
        className={`max-w-9xl px-6 mx-auto${style === 'style-1' ? ' py-0' : ' py-16'}`}
      >
        <FadeInOpacity className="max-w-8xl mx-auto">
          {children}
        </FadeInOpacity>
      </div>
    </section>
  );
};

const Columns = ({ children }) => (
  <div className="columns">
    <Switcher className="sm:px-4 mb-4 gap-6 lg:gap-8 2xl:gap-12">
      {children}
    </Switcher>
  </div>
);

const Column = ({ children }) => (
  <Stack className="column" style={{ '--space': 'var(--space-6)' }}>
    {children}
  </Stack>
);

const SectionHeader = ({ headings }) => {
  if (headings?.length) {

    // Add HTML break after colon in the header string.
    const splitHeadings = headings.map((heading) => heading.replace(/:/g, ':<br />'));

    return (
      <div className="section-header prose prose-custom md:mx-auto mb-12">
        <h2 className="section-heading md:text-center">
          {splitHeadings.length > 1
            ? (
              <>
                <span className="block mb-6 font-normal font-trade text-[1.5rem] sm:text-base 2xl:text-[2.25rem]" dangerouslySetInnerHTML={{ __html: splitHeadings[0] }} />
                <span className="block" dangerouslySetInnerHTML={{ __html: splitHeadings[1] }} />
              </>
            ) : (
              <>{headings[0]}</>
            )}
        </h2>
      </div>
    );
  }
  return null;
};

const SectionSummary = ({ html }) => (
  <div className="section-summary prose prose-custom md:mx-auto mb-12">
    <span dangerouslySetInnerHTML={{ __html: html }} />
  </div>
);

const PrismicSliceLayout = ({ sections }) => {
  return (
    <Stack className="layout" style={{ '--space': 'var(--space-24)' }}>
      {sections.length > 0 && sections.map((section, i) => {
        return (
          <Section key={`section-${i}`} section={section}>
            <SectionHeader headings={section.headings} />
            {section.summary?.primary?.rich_text?.html && <SectionSummary html={section.summary.primary.rich_text.html} />}
            {section.columns.length > 0
              && (
              <Columns>
                {section.columns.map((column, j) => {
                  return (
                    <Column key={`section-${i}-column-${j}`}>
                      {column.slices.length > 0 && column.slices.map((slice, k) => {
                        switch (slice.slice_type) {
                          case 'blockquote':
                            return <Blockquote key={`section-${i}-column-${j}-slice-${k}`} slice={slice} />;
                          case 'image':
                            return <Image key={`section-${i}-column-${j}-slice-${k}`} slice={slice} />;
                          case 'image_grid':
                            return <ImageGrid key={`section-${i}-column-${j}-slice-${k}`} slice={slice} />;
                          case 'body':
                            return <RichText key={`section-${i}-column-${j}-slice-${k}`} slice={slice} />;
                          case 'jump_links':
                            return <JumpLinks key={`section-${i}-column-${j}-slice-${k}`} slice={slice} />;
                          default:
                            return null;
                        }
                      })}
                    </Column>
                  )
                })}
              </Columns>
            )}
          </Section>
        );
      })}
    </Stack>
  );
};

export default PrismicSliceLayout;
