import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import { Section } from '../Layout';

/* If this is changed also change header in news/index.js */

import { menu } from '../../data';

const SectionHeader = ({ section, title }) => {
  let sectionPage = [];
  if (section) {
    sectionPage = menu.filter(item => item.prismicId === section.prismicId);
  }
  return (
    <Section className="bg-off-white py-16 min-h-[10rem]">
      <div className="text-center">
        {(section?.data?.title?.text && sectionPage.length > 0)
          && (
          <h1 className="font-trade text-xl">
            <Link
              to={sectionPage[0].path}
              className="no-underline"
            >
              {section.data.title.text}
            </Link>
          </h1>
        )}
        {title
          && (
          <h1 className="font-trade text-xl">
            {title}
          </h1>
        )}
      </div>
    </Section>
  )
};

export default SectionHeader;