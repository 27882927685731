import React, { useEffect, useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Transition } from '@headlessui/react';

import { CoverWrapper } from './Cover';
import { VideoButton } from '../Page';
import { VideoPlayer } from '../Video';

const CoverVideo = () => {

  const data = useStaticQuery(graphql`
    query {
      poster: file(name: {eq: "poster-640w"}, ext: {eq: ".webp"}) {
        publicURL
      }
      posterLarge: file(name: {eq: "poster"}, ext: {eq: ".webp"}) {
        publicURL
      }
    }
  `);

  const [loadVideo, setLoadVideo] = useState(false);

  useEffect(() => {
    // We need this since window isn't available during builds.
    if (typeof window === 'undefined') return;

    // Get minimum screen width.
    const matchMedia = window.matchMedia('(min-width: 640px)');

    setLoadVideo(matchMedia.matches);

    matchMedia.addEventListener('change', (e) => {
      setLoadVideo(e.matches);
    });
  }, []);

  return (
    <CoverWrapper>
      <div className="sm:pt-0">
        <div className="aspect-w-16 aspect-h-9"> {/* w-full h-[1080px] sm:w-auto sm:h-auto sm:aspect-w-16 sm:aspect-h-9 */}
          {loadVideo
            ? (
            <VideoPlayer poster={data.posterLarge?.publicURL || null} />
          ) : (
            <>
              {data.poster?.publicURL
                && (
                <>
                  <div className="text-white z-10 flex justify-center items-center bg-[rgba(0,0,0,0.25)]">
                    <div className="absolute">
                      <VideoButton>
                        Watch Video
                      </VideoButton>
                    </div>
                  </div>
                  <img src={data.poster.publicURL} alt="The smiling faces of two entrepreneurs (screen capture from Abler Nordic video)." loading="eager" className="object-cover h-full sm:h-auto" />
                </>
              )}
            </>
          )}
        </div>
      </div>
    </CoverWrapper>
  );
}

export default CoverVideo;
