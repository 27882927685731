export const canUseDOM = !!(
  typeof window !== 'undefined' &&
  window.document &&
  window.document.createElement
);

export function headerClean(str) {
  let newStr = str.trim();

  // Check word count first.
  let splitStr = str.split(' ');
  if (splitStr.length < 2) {
    return newStr;
  }

  let lastIndex = newStr.lastIndexOf(' ');
  newStr = newStr.substr(0, lastIndex) + '&nbsp' + newStr.substr(lastIndex + 1);
  newStr = newStr.replace(' | ', '&nbsp;|&nbsp;');
  return newStr;
};

export function prettyUrl(str) {
  let newStr = str.replace(/(^\w+:|^)\/\//, '');
  return newStr.replace(/\/$/, "");
}
