import React, { useEffect, useState } from 'react';
import { Link } from 'gatsby';
import { LinkIcon } from '@heroicons/react/24/solid';

import { Stack } from '../EveryLayout';
import { Modal } from '../Misc/Modal';
import { BadgeSmall } from './';
import { YouTubeEmbed } from './Embeds';

import { useCookieWatcher } from '../../hooks/useCookieWatcher';

const VideoModal = ({ watchVideo, showModal, modalOpenState }) => {

  // No consent by default. We don't care (yet) if consent checker is loaded.
  const [cookieConsent, setCookieConsent] = useState(false);

  // Watch CookieYes cookie value.
  let cookie = useCookieWatcher('cookieyes-consent', { valueOnly: true });

  useEffect(() => {
    // Only continue if consent hasn't already been given since we can't
    // revoke third-party cookies.
    if (!cookieConsent) {
      // If cookie value is present, that means the user interacted with the
      // consent checker. Check value of cookie and update state.
      if (cookie !==  null && typeof cookie !== 'undefined') {
        // Convert string to object.
        const parsedCookie = cookie.split(',').reduce((prev, current) => {
          const [name, ...value] = current.split(':');
          prev[name] = value.join(':');
          return prev;
        }, {});
        // If user has accepted advertisement cookies, set state to true.
        if (parsedCookie?.advertisement) {
          setCookieConsent(parsedCookie.advertisement === 'yes');
        }
      }
    }
  }, [cookie, cookieConsent, setCookieConsent]);

  return (
    <Modal
      open={showModal}
      handleSetOpen={modalOpenState}
      title=""
      className="w-full"
    >
      <div className="_switcher">
        <YouTubeEmbed embed={watchVideo.data.embed} consent={cookieConsent} />
        <Stack className="max-w-prose lg:mx-6 xl:mx-12">
          <h3 className="text-xl sm:text-2xl">
            <span>{watchVideo.data.title?.text}</span>
          </h3>
          {watchVideo.data.description?.html && (
            <div className="prose prose-custom mt-0">
              <span
                className="block"
                dangerouslySetInnerHTML={{ __html: watchVideo.data.description?.html }}
              />
            </div>
          )}
          {watchVideo.data.tag_group?.length > 0 && (
            <div className="mt-4 2xl:mt-8">
              {watchVideo.data.tag_group.map((tagGroup, i) => {
                if (tagGroup.tag !== null) {
                  return (
                    <BadgeSmall to={`/videos/tag/${tagGroup.tag.document.uid}`} key={`main-video-badge-${i}`}>
                      {tagGroup.tag.document.data.name}
                    </BadgeSmall>
                  );
                }
              })}
            </div>
          )}
        </Stack>
      </div>
      <div className="flex 2xl:justify-end mt-8">
        <Link
          to={`/video/${watchVideo.uid}`}
          className="inline-flex gap-2 text-xs"
        >
          <LinkIcon className="h-6 w-6" aria-hidden="true" /> Permalink
        </Link>
      </div>
    </Modal>
  );
};

export default VideoModal;