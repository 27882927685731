import React from 'react';
import PropTypes from 'prop-types';

import { FadeInOpacity } from '../Misc/FadeIn';

const DataPoint = ({ children, number, className = '' }) => (
  <FadeInOpacity className={`inline-block bg-transparent p-2 border border-dashed ${className}`}>
    {/* waiting for font - 165px > 220px, lh 198px > 264px */}
    <span className="block font-numbers leading-none text-7xl mb-3 leading-[0.8]">
      {number}
    </span>
    {/* waiting for font - 36px > 40px, lh 36px > 46px */}
    <span className="block text-lg">
      {children}
    </span>
  </FadeInOpacity>
);

DataPoint.propTypes = {
  children: PropTypes.node.isRequired,
  number: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default DataPoint;
