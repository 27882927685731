import React from 'react';
import PropTypes from 'prop-types';

import { TeaserArticleAlt } from '../';
import { FadeInOpacity } from '../../Misc/FadeIn';

const ViewNewsFeature = ({ articles }) => {

  return (
    <>
      {articles.edges.length > 0 && (
        <div className="grid gap-16 sm:grid-cols-2 xl:grid-cols-4 sm:gap-12 sm:mx-12 xl:gap-16 xl:mx-16 2xl:gap-20 2xl:mx-20">
          {articles.edges.slice(0, 4).map(({ node }, i) => (
            <FadeInOpacity className="flex xl:block items-center" key={`page-news-1-${i}`}>
              <TeaserArticleAlt
                {...{ node }}
              />
            </FadeInOpacity>
          ))}
        </div>
      )}
    </>
  );
};

ViewNewsFeature.propTypes = {
  articles: PropTypes.shape({}).isRequired,
};

export default ViewNewsFeature;