import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { ArrowRightIcon } from '@heroicons/react/24/solid';
import {
  Popover,
  Transition
} from '@headlessui/react';

import { Cluster } from '../../EveryLayout';
import { Section } from '../../Layout';
import { ListHoverItem } from '../../Page';

import { usePrismicPage } from '../../../hooks';
import { headerClean } from '../../../helpers';

const MainMenu = ({ data }) => {
  const { pages } = usePrismicPage();

  const trackingMenu = (open) => {
    if (open) {
      document.body.classList.add('menu-open');
    }
    else {
      document.body.classList.remove('menu-open');
    }
  }

  return (
    <Popover className="">
      {({ open }) => {

        useEffect(() => {
           trackingMenu(open)
        }, [open])

        return (
          <>
            <Popover.Button
              className="z-50 link p-2 absolute top-0 right-2"
            >
              {/* 28px > 40px, lh 46px */}
              <span className="text-[1.75rem] sm:text-lg 2xl:text-[2.5rem] leading-[1.65] sm:leading-[var(--base-line-height)]">Menu</span>
            </Popover.Button>
            <Transition
              enter="transition-opacity ease-linear duration-200"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Popover.Overlay className="fixed inset-0 bg-gray-900 opacity-30" />
            </Transition>
            <Transition
              enter="transition duration-200 ease-out"
              enterFrom="transform translate-y-[-100%] opacity-0"
              enterTo="transform translate-y-0 opacity-100"
              leave="transition duration-50 ease-out"
              leaveFrom="transform translate-y-0 opacity-100"
              leaveTo="transform translate-y-[-100%] opacity-0"
              className="absolute translate-y-[-100%] inset-x-0"
            >
              <Popover.Panel className="h-[calc(100vh-61px)] overflow-y-auto bg-nordic-blue-500 py-4 flex flex-col justify-between shadow-md md-tall:h-auto">
                <div>
                  <div className="px-4">
                    <div className="max-w-9xl mx-auto ">
                      <div className="columns-xs gap-6">
                        {data.map((item, i) => {
                          if (item.children.length > 0) {
                            return (
                              <div
                                key={`main-menu-1-${i}`}
                                className={`break-inside-avoid-column max-w-[30rem] 2xl:break-after-column`}
                              >
                                <MenuLink item={item} pages={pages} />
                                <ul className="divide-y divide-color-base border-y border-color-base my-6">
                                  {item.children.map((child, j) => {
                                    return (
                                      <li key={`main-menu-2-${j}`}>
                                        <MenuLinkChild item={child} pages={pages} />
                                      </li>
                                    );
                                  })}
                                </ul>
                              </div>
                            );
                          }
                        })}
                      </div>
                    </div>
                  </div>
                  <div className="px-4 md:py-8">
                    <div className="max-w-9xl mx-auto ">
                      <Cluster className="gap-8 block sm:flex">
                        {data.map((item, i) => {
                          if (item.children.length === 0) {
                            return (
                              <div
                                key={`main-menu-3-${i}`}
                              >
                                <MenuLink item={item} pages={pages} />
                              </div>
                            );
                          }
                        })}
                      </Cluster>
                    </div>
                  </div>
                </div>
                <Cluster className="justify-between px-4">
                  <div />
                  <div>
                    {/* ? > 32px, lh ? > 32px */}
                    <Popover.Button
                      type="button"
                      className="p-2 link text-base"
                    >
                      <span>Close</span>
                    </Popover.Button>
                  </div>
                </Cluster>
              </Popover.Panel>
            </Transition>
          </>
        )
      }}
    </Popover>
  );
};

const MenuLink = ({ item, pages }) => {
  let label = item.label;
  if (item.prismicId) {
    const prismicPages = pages.filter(page => page.node.prismicId === item.prismicId);
    if (prismicPages.length > 0) {
      label = prismicPages[0].node.data.title.text;
    }
  }
  return (
    <>
      {/* ? > 28px, lh ? > 48px */}
      <Popover.Button
        as={Link}
        to={item.path}
        className="block font-trade no-underline text-sm xs:text-[1.75rem] leading-[1.7] pt-2 xs:pt-4"
      >
        <span dangerouslySetInnerHTML={{ __html: headerClean(label) }} />
      </Popover.Button>
    </>
  )
};

const MenuLinkChild = ({ item, pages }) => {
  let label = item.label;
  if (item.prismicId) {
    const prismicPages = pages.filter(page => page.node.prismicId === item.prismicId);
    if (prismicPages.length > 0) {
      label = prismicPages[0].node.data.title.text;
    }
  }
  return (
    <>
      {/* ? > 28px, lh ? > 48px */}
      {/* Line height changed to 33.6px (default) to accommodate wrapping. */}
      <Popover.Button
        as={Link}
        to={item.path}
        className="hover-row block overflow-hidden py-2 no-underline text-sm xs:text-[1.75rem]"
      >
        <ListHoverItem>
          <span dangerouslySetInnerHTML={{ __html: headerClean(label) }} />
        </ListHoverItem>
      </Popover.Button>
    </>
  )
};

MainMenu.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
      children: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string,
          path: PropTypes.string,
        }),
      ),
    }),
  ).isRequired,
};

MenuLink.propTypes = {
  item: PropTypes.shape({}),
  pages: PropTypes.arrayOf(
    PropTypes.shape({}),
  ),
};

MenuLinkChild.propTypes = {
  item: PropTypes.shape({}),
  pages: PropTypes.arrayOf(
    PropTypes.shape({}),
  ),
};

export default MainMenu;
