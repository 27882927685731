import React from 'react';
import PropTypes from 'prop-types';

const Section = ({ children, className = '' }) => (
  <section className={`${className}`}>
    <div className="max-w-9xl px-6 mx-auto">
      {children}
    </div>
  </section>
);

Section.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export default Section;
