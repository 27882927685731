import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Popover, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/24/solid';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
};

const Menu = ({ menuName, content, footer, inset }) => (
  <Popover className="relative">
    {({ open }) => (
      <>
        <Popover.Button
          className={classNames(
            open ? 'text-gray-900' : 'text-gray-500',
            'group bg-white inline-flex items-center uppercase text-base font-medium hover:text-gray-900 focus:outline-none'
          )}
        >
          <span>{menuName}</span>
          <span className="ml-1"><ChevronDownIcon className="flex-shrink-0 h-6 w-6 text-gray-400" aria-hidden="true" /></span>
        </Popover.Button>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-200"
          enterFrom="opacity-0 -translate-y-1"
          enterTo="opacity-100 translate-y-0"
          leave="transition ease-in duration-150"
          leaveFrom="opacity-100 translate-y-0"
          leaveTo="opacity-0 -translate-y-1"
        >
          <Popover.Panel className={`bg-gray-100 absolute z-10 left-1/4 mt-3 w-screen max-w-sm -translate-x-1/4 transform px-4 sm:px-0 lg:max-w-sm ${inset}`}>
            <div className="p-2">
              <div className="bg-white p-6">
                {content}
              </div>
              {/* Menu footer */}
              {footer && (
                <div className="bg-white mx-2 mb-2">
                  <div className="p-6">
                    {footer}
                  </div>
                </div>
              )}
            </div>
          </Popover.Panel>
        </Transition>
      </>
    )}
  </Popover>
);

Menu.propTypes = {
  menuName: PropTypes.string.isRequired,
  content: PropTypes.object.isRequired,
  footer: PropTypes.object,
  inset: PropTypes.string,
};

Menu.defaultProps = {
  menuName: 'Menu link',
  content: <>Content</>,
  inset: '',
};

export {
  Menu,
};