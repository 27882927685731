import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';

import { Switcher } from '../../EveryLayout';
import { Layout } from '../../PrismicSlices';

import { menu } from '../../../data';

/**
 * This is an alternate version of the Page component that uses Prismic layout
 * slices.
 **/
const ContentLayoutPage = ({ section, title, page, image, hidePageHeader = false }) => {

  let sectionPage = [];
  if (section) {
    sectionPage = menu.filter(item => item.prismicId === section.prismicId);
  }

  let isContent = false;
  if (page.fields?.sections[0]?.columns) {
    isContent = page.fields?.sections[0]?.columns.length > 0;
  }

  return (
    <div>
      {!hidePageHeader
        && (
        <div className="md:px-6 pt-6">
          <div className="max-w-9xl mx-auto">
            <div className="">
              <Switcher className="gap-4 mb-16">
                {image
                  && (
                  <figure className="max-w-[800px] mx-auto">
                    <GatsbyImage
                      image={image.gatsbyImageData}
                      alt={image.image?.alt ? item.image.alt : ''}
                      objectFit="cover"
                    />
                  </figure>
                )}
                <div className="bg-nordic-blue-500">
                  <div className="prose prose-custom mx-6 mt-8 mb-12 xl:mx-12 xl:mt-12 xl:mb-16">
                    {title
                      && (
                        <h2 className="mt-0 mb-6">{title}</h2>
                    )}
                    {page.data?.subtitle
                      && (
                      <p className={`text-lg`}>{page.data.subtitle}</p>
                    )}
                  </div>
                </div>
              </Switcher>
            </div>
          </div>
        </div>
      )}
      {isContent
        && (
        <Layout sections={page.fields.sections} />
      )}
    </div>
  )
};

export default ContentLayoutPage;
