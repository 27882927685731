import React from 'react';

/**
 * Wordmark 1425px x 193px
 * Monogram 369px x 168px
 */

/** Important for storybook! */
import Image1 from '../../images/_RGB_AblerNordic_Wordmark-White.svg';
import Image2 from '../../images/_RGB_AblerNordic_Wordmark-Black.svg';
import Image4 from '../../images/_RGB_AblerNordic_Monogram-Black.svg';

const LogoWordmarkWhite = (props) => {

  const attrs = {
    className: props.className ? props.className : 'max-w-xs',
  };

  const alt = props.alt ? props.alt : 'Abler Nordic Logo';

  return (
    <div {...attrs}>
      <img
        src={Image1}
        className="w-full"
        alt={alt}
      />
    </div>
  );
};

const LogoWordmarkBlack = (props) => {

  const attrs = {
    className: props.className ? props.className : 'max-w-xs',
  };

  const alt = props.alt ? props.alt : 'Abler Nordic Logo';

  return (
    <div {...attrs}>
      <img
        src={Image2}
        className="w-full"
        alt={alt}
      />
    </div>
  );
};

const LogoMonogramBlack = (props) => {

  const attrs = {
    className: props.className ? props.className : 'max-w-xs',
  };

  const alt = props.alt ? props.alt : 'Abler Nordic Logo';

  return (
    <div {...attrs}>
      <img
        src={Image4}
        className="w-full"
        alt={alt}
      />
    </div>
  );
};

export {
  LogoWordmarkWhite,
  LogoWordmarkBlack,
  LogoMonogramBlack
};