import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import { LogoMonogramWhite } from '../Brand';
import { Stack, Cluster } from '../EveryLayout';
import { RichTextField } from './PrismicFields';

import { headerClean } from '../../helpers';

const TeaserArticleWrapper = ({ children }) => (
  <Stack className="w-full">
    {children}
  </Stack>
);

const NodeTime = ({ node }) => (
  <>
    {/* ? > 16px, lh ? > 22px */}
    <time className="font-trade text-[1rem] sm:text-xs 2xl:text-[1.3rem] leading-[1.4] 2xl:leading-[1.2] sm:text-center">{node.data.date || node.first_publication_date}</time>
  </>
);

const HeaderInner = ({ text }) => {
  const headline = headerClean(text);
  return (
    <span dangerouslySetInnerHTML={{ __html: headline }} />
  );
};

const Header = ({ text, classes }) => {
  return (
    <h3 className={classes}>
      <HeaderInner text={text} />
    </h3>
  );
};

const TeaserArticle = ({ node }) => {

  let mainImage = null;
  if (node.data.main_image?.gatsbyImageData) {
    mainImage = node.data.main_image;
  }
  else {
    if (node.data.featured_image?.gatsbyImageData) {
      mainImage = node.data.featured_image;
    }
  }

  return (
    <TeaserArticleWrapper>
      {mainImage?.gatsbyImageData
        ? (
        <Link
          to={`/news/${node.uid}/`}
          className="relative block group"
        >
          <div aria-hidden="true" className={`p-4 z-10 absolute transition-opacity duration-200 inset-0 opacity-0 bg-transparent group-hover:opacity-100 group-hover:bg-${node.fields?.colour}`}>
            <p className={`italic text-base text-contrast-pair-${node.fields?.colour} underline decoration-1`}>Read more <span className="sr-only">about {node.data.title.text}</span></p>
          </div>

          <GatsbyImage
            image={mainImage.gatsbyImageData}
            alt={node.data.title.text}
            objectFit="contain"
            className="z-0"
          />
        </Link>
      ) : (
        <Link
          to={`/news/${node.uid}/`}
          className="relative block group"
        >
          <div aria-hidden="true" className={`p-4 z-10 absolute transition-opacity duration-200 inset-0 opacity-0 bg-transparent group-hover:opacity-100 group-hover:bg-${node.fields?.colour}`}>
            <p className={`italic text-base text-contrast-pair-${node.fields?.colour} underline decoration-1`}>Read more <span className="sr-only">about {node.data.title.text}</span></p>
          </div>

          <div aria-hidden="true" className="bg-soft-black px-12 py-24 flex items-center justify-center w-full">
            <LogoMonogramWhite
              alt=""
              width="300"
              height="136"
              className="max-w-[150px]"
            />
          </div>
        </Link>
      )}
      <NodeTime node={node} />
      <div>
        {/*
        {node.data.category && (
          <Badge>
            {node.data.category}
          </Badge>
        )}
        */}
        {/* 21px > 40px, lh 25px > 46px */}
        <Header text={node.data.title.text} classes="text-[1.3rem] sm:text-sm 2xl:text-[2.5rem] 2xl:leading-[1.15]" />
        {/* We are adding aria-label below to pass a11y checks even though the element is hidden from screen readers */}
        <Link
          to={`/news/${node.uid}/`}
          className="block text-sm mt-4"
          aria-hidden="true"
          tabIndex="-1"
        >
          Read more <span className="sr-only">about {node.data.title.text}</span>
        </Link>
      </div>
    </TeaserArticleWrapper>
  )
};

const TeaserArticleFeature = ({ node }) => {

  let mainImage = null;
  if (node.data.main_image?.gatsbyImageData) {
    mainImage = node.data.main_image;
    if (node.data.main_image.thumbnails?.Portrait?.gatsbyImageData) {
      mainImage = node.data.main_image.thumbnails.Portrait;
    }
  }
  else {
    if (node.data.featured_image?.gatsbyImageData) {
      mainImage = node.data.featured_image;
    }
  }

  return (
    <TeaserArticleWrapper>
      {mainImage?.gatsbyImageData
        ? (
        <Link
          to={`/news/${node.uid}/`}
          className="block z-0"
        >
          <div className="relative group bg-nordic-blue-500 px-16 py-10 2xl:py-16 2xl:px-24">
            <div aria-hidden="true" className={`p-4 z-10 absolute transition-opacity duration-200 inset-0 opacity-0 bg-transparent group-hover:opacity-100 group-hover:bg-${node.fields?.colour}`}>
              <p className={`italic text-base text-contrast-pair-${node.fields?.colour} underline decoration-1`}>Read more <span className="sr-only">about {node.data.title.text}</span></p>
            </div>
            <GatsbyImage
              image={mainImage.gatsbyImageData}
              alt={node.data.title.text}
              objectFit="contain"
              className="z-0"
            />
          </div>
        </Link>
      ) : (
        <Link
          to={`/news/${node.uid}/`}
          className="relative block group"
        >
          <div aria-hidden="true" className="relative group bg-nordic-blue-500 2xl:py-16 2xl:px-24">
            <div className={`p-4 z-10 absolute transition-opacity duration-200 inset-0 opacity-0 bg-transparent group-hover:opacity-100 group-hover:bg-${node.fields?.colour}`}>
              <p className={`italic text-base text-contrast-pair-${node.fields?.colour} underline decoration-1`}>Read more <span className="sr-only">about {node.data.title.text}</span></p>
            </div>

            <div className="py-24 flex items-center justify-center w-full sm:px-12">
              <LogoMonogramWhite
                alt=""
                width="300"
                height="136"
                className="max-w-[150px]"
              />
            </div>
          </div>
        </Link>
      )}
      <NodeTime node={node} />
      <div>
        {/*
        {node.data.category && (
          <Badge>
            {node.data.category}
          </Badge>
        )}
        */}
        {/* 21px > 40px, lh 25px > 46px */}
        <Header text={node.data.title.text} classes="text-[1.3rem] sm:text-sm 2xl:text-[2.5rem] 2xl:leading-[1.15]" />
        {/* We are adding aria-label below to pass a11y checks even though the element is hidden from screen readers */}
        <Link
          to={`/news/${node.uid}/`}
          className="block text-sm mt-4"
          aria-hidden="true"
          tabIndex="-1"
        >
          Read more <span className="sr-only">about {node.data.title.text}</span>
        </Link>
      </div>
    </TeaserArticleWrapper>
  )
};

const TeaserArticleRelated = ({ node }) => {

  let mainImage = null;
  if (node.data.main_image?.gatsbyImageData) {
    mainImage = node.data.main_image;
  }
  else {
    if (node.data.featured_image?.gatsbyImageData) {
      mainImage = node.data.featured_image;
    }
  }

  return (
    <Cluster as="article" className="2xl:gap-6 sm:flex-nowrap">
      {mainImage?.gatsbyImageData
        && (
        <div className="mx-auto">
          <GatsbyImage
            image={mainImage.gatsbyImageData}
            alt={node.data.title.text}
            objectFit="contain"
          />
        </div>
      )}
      <div>
        <header>
          <h2 className="text-base my-0">
            <Link to={`/news/${node.uid}/`}>
              <HeaderInner text={node.data.title.text} />
            </Link>
          </h2>
        </header>
        {node.data.teaser && (
          <>
            <RichTextField data={node.data.teaser} className="text-[1.3rem]" />
            {/* We are adding aria-label below to pass a11y checks even though the element is hidden from screen readers */}
            <Link
              aria-hidden="true"
              to={`/news/${node.uid}/`}
            >
              <p className={`text-[1.3rem] underline decoration-1`}>Read more <span className="sr-only">about {node.data.title.text}</span></p>
            </Link>
          </>
        )}
      </div>
    </Cluster>
  );
};

const TeaserArticleAlt = ({ node }) => {

  let mainImage = null;
  if (node.data.main_image?.gatsbyImageData) {
    mainImage = node.data.main_image;
  }
  else {
    if (node.data.featured_image?.gatsbyImageData) {
      mainImage = node.data.featured_image;
    }
  }

  return (
    <TeaserArticleWrapper>
      {mainImage?.gatsbyImageData
        ? (
        <Link
          to={`/news/${node.uid}/`}
          className="relative block group"
        >
          <div aria-hidden="true" className={`p-4 z-10 absolute transition-opacity duration-200 inset-0 opacity-0 bg-transparent group-hover:opacity-100 group-hover:bg-${node.fields?.colour}`}>
            <p className={`italic text-base text-contrast-pair-${node.fields?.colour} underline decoration-1`}>Read more <span className="sr-only">about {node.data.title.text}</span></p>
          </div>

          <GatsbyImage
            image={mainImage.gatsbyImageData}
            alt=""
            className="xl:max-h-[400px]"
            objectFit="contain"
            className="z-0"
          />
        </Link>
      ) : (
        <Link
          to={`/news/${node.uid}/`}
          className="relative block group"
        >
          <div aria-hidden="true" className={`p-4 z-10 absolute transition-opacity duration-200 inset-0 opacity-0 bg-transparent group-hover:opacity-100 group-hover:bg-${node.fields?.colour}`}>
            <p className={`italic text-base text-contrast-pair-${node.fields?.colour} underline decoration-1`}>Read more <span className="sr-only">about {node.data.title.text}</span></p>
          </div>

          <div className="bg-nordic-blue-500 px-12 py-24 flex items-center justify-center w-full">
            <LogoMonogramWhite
              alt=""
              width="300"
              height="136"
              className="max-w-[150px]"
            />
          </div>
        </Link>
      )}
      <NodeTime node={node} />
      <div>
        {/*
        {node.data.category && (
          <BadgeSmall>
            {node.data.category}
          </BadgeSmall>
        )}
        */}
        {/* ? > 21px, lh ? > 25px */}
        <Header text={node.data.title.text} classes="text-[1.3rem]" />
        {/* We are adding aria-label below to pass a11y checks even though the element is hidden from screen readers */}
        <Link
          to={`/news/${node.uid}/`}
          className="block text-[1.3rem] mt-4"
          aria-hidden="true"
          tabIndex="-1"
        >
          Read more <span className="sr-only">about {node.data.title.text}</span>
        </Link>
      </div>
    </TeaserArticleWrapper>
  )
};

TeaserArticleWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

TeaserArticle.propTypes = {
  node: PropTypes.shape({
    first_publication_date: PropTypes.string,
    uid: PropTypes.string,
    data: PropTypes.shape({
      date: PropTypes.string,
      featured_image: PropTypes.shape({
        alt: PropTypes.string,
        gatsbyImageData: PropTypes.shape({})
      }),
      fields: PropTypes.shape({
        colour: PropTypes.string,
      }),
      main_image: PropTypes.shape({
        alt: PropTypes.string,
        gatsbyImageData: PropTypes.shape({})
      }),
      title: PropTypes.shape({
        text: PropTypes.string,
      })
    }),
  }).isRequired,
};

TeaserArticleFeature.propTypes = {
  node: PropTypes.shape({}).isRequired,
};

TeaserArticleRelated.propTypes = {
  node: PropTypes.shape({}).isRequired,
};

TeaserArticleAlt.propTypes = {
  node: PropTypes.shape({}).isRequired,
};

export {
  TeaserArticle,
  TeaserArticleAlt,
  TeaserArticleFeature,
  TeaserArticleRelated,
};
