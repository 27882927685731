import React, { useRef, useEffect, useState } from 'react'

import './videoControls.css';

const VideoControls = ({  
  media,
  play,
  playing,
  stop,
  setPlaying,
}) => {
  const timerWrapper = useRef(null); // wrapper
  const timerBar = useRef(null); // indicate amount of video played
  const timer = useRef(null); // text time readout

  // const [timerBarWidth, setTimerBarWidth] = useState(0);
  const [timerBarStyle, setTimerBarStyle] = useState({});
  const [timerText, setTimerText] = useState('00:00');


  useEffect(() => {

    const setTime = () => {
      if (timerBar && timerWrapper) {
        const minutes = Math.floor(media.current.currentTime / 60);
        const seconds = Math.floor(media.current.currentTime - minutes * 60);

        const minuteValue = minutes.toString().padStart(2, '0');
        const secondValue = seconds.toString().padStart(2, '0');

        const mediaTime = `${minuteValue}:${secondValue}`;
        setTimerText(mediaTime);

        const barLength = Math.ceil(timerWrapper.current.clientWidth * (media.current.currentTime/media.current.duration));
        setTimerBarStyle({ width: barLength });
      }
    }

    if (media) {
      media.current.addEventListener('timeupdate', setTime);
    }
  }, [media]);

  const handleVolume = (e) => {
    if (media) {
      media.current.volume = e.target.value / 100;
    }
  };

  
  return (
    <div className="flex justify-center">
      <div
        id="videoPlayerControls"
        className="bg-white opacity-60 w-1/3 p-1 rounded-2xl">
        <button
          className="px-2 font-serif"
          aria-label="play pause toggle"
          ref={play}
          onClick={() => setPlaying(!playing)}
          >{
            playing
              ? 'Pause'
              : 'Play'
            }</button>

        <button
          className="px-2 font-serif"
          ref={stop}
          onClick={() => setPlaying(false)}>Stop</button>

        <input
          type="range"
          onChange={(e) => handleVolume(e)}
        />
      </div>

      <div
        className="timer text-black"
        ref={timerWrapper}
      >
        <div
          id="timerBar"
          ref={timerBar}
          style={timerBarStyle}
          className='bg-nordic-blue-500 w-0'
        >
        </div>
          <span
            aria-label="timer"
            ref={timer}
            className="font-trade"
          >{timerText}</span>
      </div>
    </div>
  );
}

export default VideoControls;
