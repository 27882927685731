import React from 'react';
import LiteYouTubeEmbed from 'react-lite-youtube-embed';
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css';

const YouTubeEmbed = ({ embed, consent = true }) => {

  const url = new URL(embed.embed_url);
  const youTubeID = url.pathname.replace(/\//g, '');

  // poster="maxresdefault" loads a higher resolution version of the thumbnail.
  return (
    <div>
      <LiteYouTubeEmbed
        id={youTubeID}
        title="Embedded YouTube video"
        cookie={false}
        webp={true}
        lazyImage={true}
        wrapperClass={`yt-lite ${youTubeID}`}
        offsite={consent === false}
        poster="maxresdefault"
      />
      <div className="prose prose-custom mt-4">
        <p className="text-xs md:text-[1.2rem] italic">
          To play video without leaving the website you must <a href="#" className="cky-banner-element">consent to third-party cookies</a>.
        </p>
      </div>
    </div>
  );
};

export default YouTubeEmbed;
