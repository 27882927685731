import React from 'react';

import { Image } from './';

import './image-grid.css';

const PrismicSliceImageGrid = ({ slice }) => {
  return (
    <div className={`image-grid image-grid-${slice.items.length} my-8 mx-auto inline-grid items-start gap-4`}>
      {slice.items.map((item, i) => (
        <div key={`${slice.id}-${i}`}>
          <Image slice={item} />
        </div>
      ))}
    </div>
  );
};

export default PrismicSliceImageGrid;
