import React from 'react';
import { Link } from 'gatsby';
import {
  ArrowDownIcon,
  ArrowUpRightIcon
} from '@heroicons/react/24/outline';

const Button = (props) => {
  const { children } = props;

  const attrs = {};
  if (props.onClick) {
    attrs.onClick = props.onClick;
  }

  return (
    <>
      {/* 18px > 21px, lh 22px > 25px*/}
      <button
        type="button"
        className={`inline-flex items-center rounded-full border border-soft-black border-dashed text-[1.125rem] sm:text-[1.3125rem] px-3 py-2 ${props.className ? props.className : ''}`}
        {...attrs}
      >
        <span className="pl-4 pr-3">{children}</span>
      </button>
    </>
  );
};

const FormButton = (props) => {
  const { children } = props;
  const attrs = {
    className: props.className,
  };
  return (
    <>
      {/* 18px > 21px, lh 22px > 25px*/}
      <button
        type="submit"
        className={`inline-flex items-center rounded-full border border-soft-black border-dashed text-[1.125rem] sm:text-[1.3125rem] px-3 py-2 ${attrs.className}`}
      >
        <div className="px-3">{children}</div>
      </button>
    </>
  );
};


const LinkButton = (props) => {
  const { children, path } = props;
  const attrs = {
    className: props.className,
  };
  return (
    <>
      <Link
        className={`no-underline inline-flex items-center rounded-full border border-soft-black border-dashed text-[1.125rem] sm:text-[1.3125rem] px-3 py-2 ${attrs.className}`}
        to={path}
      >
        <span className="pl-4 pr-3">{children}</span>
        <ArrowDownIcon className="ml-3 -mr-1 h-8 w-8 bg-soft-black rounded-full p-2 stroke-white stroke-[3px]" aria-hidden="true" />
      </Link>
    </>
  );
};

const VideoButton = (props) => {
  const { children } = props;
  const attrs = {
    className: props.className,
  };
  return (
    <>
      <a
        className={`no-underline inline-flex items-center rounded-full border border-white border-dashed text-[1.125rem] sm:text-[1.3125rem] px-3 py-2 ${attrs.className}`}
        href="https://vimeo.com/792349008"
        target="_blank"
      >
        <span className="pl-4 pr-3">{children}</span>
        <ArrowUpRightIcon className="ml-3 -mr-1 h-8 w-8 bg-off-white rounded-full p-2 stroke-soft-black stroke-[3px]" aria-hidden="true" />
      </a>
    </>
  );
};

export {
  Button,
  FormButton,
  LinkButton,
  VideoButton,
};
