import React from 'react';
import { PlayIcon } from '@heroicons/react/24/solid';

import { TeaserVideo } from '../';
import { FadeInOpacity } from '../../Misc/FadeIn';

const WatchThumbnail = ({ video, handleOnClick }) => {
  return (
    <div className="relative">
      <div className="aspect-w-16 aspect-h-9">
        <img
          src={video.data.embed?.thumbnail_url}
          alt=""
          className="object-cover"
        />
      </div>
      <div className="absolute z-10 top-0 left-0 w-full h-full flex items-center justify-center">
        <button
          onClick={() => handleOnClick(video)}
          className="rounded-full bg-[rgba(31,30,29,0.9)] p-2"
        >
          <PlayIcon className="flex-shrink-0 h-6 w-6 text-off-white" aria-hidden="true" />
        </button>
      </div>
    </div>
  );
};


const ViewVideos = ({ videos, watchVideoSetState }) => {
  return (
    <div className="grid gap-16 sm:grid-cols-2 lg:grid-cols-3 sm:gap-12 xl:gap-16 2xl:gap-20">
      {videos.map((video, i) => {

        // Sometimes we have a node.
        if (video.node) {
          video = video.node;
        }

        // Only show YouTube videos for now.
        if (video.data.embed?.provider_name !== 'YouTube') {
          return null;
        }

        return (
          <FadeInOpacity key={`video-teaaer-${video.uid}`}>
            <TeaserVideo video={video} watchVideoSetState={watchVideoSetState} />
          </FadeInOpacity>
        );
      })}
    </div>
  );
};

export default ViewVideos;
