import React from 'react';
import PropTypes from 'prop-types';

import './everylayout.css';

const Box = (props) => {
  const { children } = props;
  const attrs = {
    className: props.className ? '_box ' + props.className : '_box',
  };

  const ComponentType = props.as || 'div';

  return (
    <ComponentType {...attrs}>
      {children}
    </ComponentType>
  );
};

/**
 * Every Layout - The Center
 * Horizontally centers the element, not its children.
 */
const Center = (props) => {
  const { children } = props;
  const attrs = {
    className: props.className ? '_center ' + props.className : '_center',
  };

  const ComponentType = props.as || 'div';

  return (
    <ComponentType {...attrs}>
      {children}
    </ComponentType>
  );
};

/**
 * Every Layout - The Center (Intrinsic)
 * Horizontally centers the element AND its children. Centers children
 * regardless of their width.
 */
const CenterIntrinsic = (props) => {
  const { children } = props;
  const attrs = {
    className: props.className ? '_center-i ' + props.className : '_center-i',
    style: props.style ? props.style : null,
  };

  const ComponentType = props.as || 'div';

  return (
    <ComponentType {...attrs}>
      {children}
    </ComponentType>
  );
};

/**
 * Every Layout - The Cluster
 */
const Cluster = (props) => {
  const { children } = props;
  const attrs = {
    className: props.className ? '_cluster ' + props.className : '_cluster',
    style: props.style ? props.style : null,
  };

  const ComponentType = props.as || 'div';

  return (
    <ComponentType {...attrs}>
      {children}
    </ComponentType>
  );
};

/**
 * Every Layout - The Grid
 */
const Grid = ({ children, className }) => {
  return (
    <div className={`_grid${ className ? ' ' + className : '' }`}>
      {children}
    </div>
  );
};

/**
 * Every Layout - The Stack
 */
const Stack = (props) => {
  const { children } = props;
  const attrs = {
    className: props.className ? '_stack ' + props.className : '_stack',
    style: props.style ? props.style : null,
  };

  const ComponentType = props.as || 'div';

  return (
    <ComponentType {...attrs}>
      {children}
    </ComponentType>
  );
};

/**
 * Every Layout - The Switcher
 */
const Switcher = (props) => {
  const { children } = props;
  const attrs = {
    className: props.className ? '_switcher ' + props.className : '_switcher',
    style: props.style ? props.style : null,
  };

  const ComponentType = props.as || 'div';

  return (
    <ComponentType {...attrs}>
      {children}
    </ComponentType>
  );
};

Box.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

Center.propTypes = {
  children: PropTypes.node,
};

CenterIntrinsic.propTypes = {
  children: PropTypes.node,
};

Cluster.propTypes = {
  children: PropTypes.node,
};

Grid.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

Stack.propTypes = {
  children: PropTypes.node,
};

Switcher.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export {
  Box,
  Center,
  CenterIntrinsic,
  Cluster,
  Grid,
  Stack,
  Switcher,
};
