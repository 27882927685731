import React from 'react';

import { socialPages } from '../../../data';

const SocialPages = () => (
  <ul>
    {socialPages.map((item, i) => (
      <li
        key={`socialPages-${i}`}
      >
        <a
          href={item.url}
          className="text-[1.3rem] leading-[1.25] underline underline-offset-2"
          rel="nofollow noreferrer"
        >
          {item.name}
        </a>
      </li>
    ))}
  </ul>
);

export default SocialPages;