import React, { createContext, useContext } from 'react';
import { m, useReducedMotion } from 'framer-motion';

import { useMediaQuery } from '../../hooks';

const FadeInStaggerContext = createContext(false);

const viewport = { once: true, margin: '0px 0px -200px' };

export function FadeIn(props) {
  let shouldReduceMotion = useReducedMotion()
  let isInStaggerGroup = useContext(FadeInStaggerContext)

  return (
    <m.div
      variants={{
        hidden: { opacity: 0, y: shouldReduceMotion ? 0 : 24 },
        visible: { opacity: 1, y: 0 },
      }}
      transition={{ duration: 0.5 }}
      {...(isInStaggerGroup
        ? {}
        : {
            initial: 'hidden',
            whileInView: 'visible',
            viewport,
          })}
      {...props}
    />
  );
};

export function FadeInOpacity(props) {
  let isInStaggerGroup = useContext(FadeInStaggerContext)

  return (
    <m.div
      variants={{
        hidden: { opacity: 0 },
        visible: { opacity: 1 },
      }}
      transition={{ duration: 0.5 }}
      {...(isInStaggerGroup
        ? {}
        : {
            initial: 'hidden',
            whileInView: 'visible',
            viewport,
          })}
      {...props}
    />
  );
};

export function FadeInStagger({ faster = false, query = '(min-width: 1024px)', ...props }) {

  const matchQuery = useMediaQuery(query);

  if (!matchQuery) {
    return (
      <div {...props} />
    );
  }

  return (
    <FadeInStaggerContext.Provider value={true}>
      <m.div
        initial="hidden"
        whileInView="visible"
        viewport={viewport}
        transition={{ staggerChildren: faster ? 0.12 : 0.2 }}
        {...props}
      />
    </FadeInStaggerContext.Provider>
  );
};
