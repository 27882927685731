import React from 'react';
import PropTypes from 'prop-types';
import { Link, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from 'react-share';
import Seo from '@laradevitt/gatsby-theme-just-basics/src/components/seo';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';

import { LogoMonogramWhite } from '../components/Brand';
import {
  Stack,
  Switcher
} from '../components/EveryLayout';
import { Section } from '../components/Layout';
import {
  Badge,
  SocialShareWrapper,
  SocialShareEmail,
  SocialShareFacebook,
  SocialShareLinkedIn,
  SocialShareTwitter
} from '../components/Page';
import { FadeInOpacity } from '../components/Misc/FadeIn';
import { RichTextField } from '../components/Page/PrismicFields';
import {
  Blockquote,
  InfoBoxes
} from '../components/Page/Slices';
import { ViewRelatedNews } from '../components/Page/Views';

/* eslint-disable react/prop-types */
export const Head = ({ data }) => {

  const { prismicArticle } = data;

  let seoImageUrl = prismicArticle.data.featured_image?.thumbnails?.OpenGraph?.url || prismicArticle.data.featured_image?.url || '';
  if (prismicArticle.data.main_image?.url) {
    seoImageUrl = prismicArticle.data.main_image?.thumbnails?.OpenGraph?.url || prismicArticle.data.main_image?.url;
  }

  return (
    <Seo
      title={prismicArticle.data.title.text}
      description={prismicArticle.data.teaser?.text || ''}
      image={seoImageUrl}
      path={`/news/${prismicArticle.uid}/`}
    />
  );
};
/* eslint-enable react/prop-types */

const Post = ({
  data,
}) => {
  const { site, prismicArticle } = data;

  let mainImage = null;
  if (prismicArticle.data.main_image?.gatsbyImageData) {
    mainImage = prismicArticle.data.main_image;
    if (prismicArticle.data.main_image.thumbnails?.Portrait?.gatsbyImageData) {
      mainImage = prismicArticle.data.main_image.thumbnails.Portrait;
    }
  }
  else {
    if (prismicArticle.data.featured_image?.url) {
      mainImage = prismicArticle.data.featured_image;
    }
  }

  const publishedDate = prismicArticle.data.date || prismicArticle.first_publication_date;

  // If there is no related content, prismicArticle.data.related should be empty
  // but instead it has one item: { related_content: null }. Let's filter it out.
  prismicArticle.data.related = prismicArticle.data.related.filter((item) => item.related_content);

  return (
    <div className="bg-off-white pb-16 2xl:pb-32">
      <Section className="bg-off-white py-4">
        <div className="max-w-8xl">
          <FadeInOpacity className="flex flex-wrap justify-between pb-12">
            <div className="mx-auto grow sm:grow-0 lg:grow lg:mx-0">
              {mainImage
              ? (
                <figure className="max-w-[800px] lg:max-w-[572px]">
                  <div className="mx-auto lg:aspect-w-3 lg:aspect-h-4">
                    <img src={mainImage.url} alt={mainImage.alt ? mainImage.alt : ''} className="object-cover max-h-[600px] lg:max-h-[none]" />
                  </div>
                  {/* 16px > 21px, lh 20px > 25px */}
                  {/* No captions yet!
                  <figcaption className="sm:text-[1.3rem] mt-3">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. In consequat arcu augue, eget sodales purus consectetur et. Nullam eget erat eros.
                  </figcaption>
                  */}
                </figure>
              ) : (
                <div className="bg-soft-black max-w-[800px] lg:max-w-[572px]">
                  <div className="mx-auto lg:aspect-w-3 lg:aspect-h-4">
                    <div className="py-24 flex items-center justify-center w-full sm:px-24">
                      <LogoMonogramWhite width="300" height="136" className="max-w-[150px]" />
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="lg:w-[60%] xl:w-[53%] flex items-center">
              <Stack className="pt-4 lg:pl-4 2xl:pl-0 mb-16">
                {prismicArticle.data.category && (
                  <div className="2xl:mb-10">
                    <Badge>
                      {prismicArticle.data.category}
                    </Badge>
                  </div>
                )}
                <div className="flex divide-x divide-soft-black">
                  <Link
                    className="inline-block pr-2 font-trade text-[1rem] sm:text-xs 2xl:text-[1.3rem] leading-[1.4] 2xl:leading-[1.2]"
                    to="/news/"
                  >
                    News
                  </Link>
                  {/* 16px > 21px, lh 22px > 25px */}
                  <time className="inline-block pl-2 font-trade text-[1rem] sm:text-xs 2xl:text-[1.3rem] leading-[1.4] 2xl:leading-[1.2]">{publishedDate}</time>
                </div>
                {/* 36px > 88px, lh 42px > 92px */}
                <h1 className="text-xl 2xl:text-[5.5rem] 2xl:leading-[1.05] 2xl:mb-10">{prismicArticle.data.title.text}</h1>
                {/* 28px > 40px, lh 34px > 46px */}
                {prismicArticle.data.teaser && (
                  <RichTextField data={prismicArticle.data.teaser} className={`text-${prismicArticle.fields?.colour} text-[1.75rem] sm:text-lg 2xl:text-[2.5rem] leading-[1.215] sm:leading-[var(--base-line-height)] 2xl:leading-[1.15] mt-8`} />
                )}
              </Stack>
            </div>
          </FadeInOpacity>
          <div>
            <SocialShareWrapper>
              <LinkedinShareButton
                title={`Abler Nordic News: ${prismicArticle.data.title?.text}`}
                summary={prismicArticle.data.teaser?.text || ''}
                source={`Shared from the ${site?.siteMetadata?.title} website`}
                url={`${site?.siteMetadata?.siteUrl}/news/${prismicArticle.uid}/`}
              >
                <SocialShareLinkedIn className="rounded-sm h-11 w-11" aria-hidden="true" />
              </LinkedinShareButton>
              <FacebookShareButton
                url={`${site?.siteMetadata?.siteUrl}/news/${prismicArticle.uid}/`}
                hashtag={`#AblerNordic`}
              >
                <SocialShareFacebook className="rounded-sm h-11 w-11" aria-hidden="true" />
              </FacebookShareButton>
              <TwitterShareButton
                url={`${site?.siteMetadata?.siteUrl}/news/${prismicArticle.uid}/`}
                title={`Abler Nordic News: ${prismicArticle.data.title.text}`}
                via={`Abler_Nordic`}
              >
                <SocialShareTwitter className="rounded-sm h-11 w-11" aria-hidden="true" />
              </TwitterShareButton>
              <EmailShareButton
                subject={`Abler Nordic News: ${prismicArticle.data.title.text}`}
                url={`${site?.siteMetadata?.siteUrl}/news/${prismicArticle.uid}/`}
                body={`Shared from the ${site?.siteMetadata?.title} website:`}
              >
                <SocialShareEmail className="rounded-sm h-11 w-11" aria-hidden="true" />
              </EmailShareButton>
            </SocialShareWrapper>
          </div>
        </div>
      </Section>
      <Section className="bg-off-white pt-4 mb-16">
        {prismicArticle.data.body?.html
          && (
          <FadeInOpacity className="max-w-8xl pb-12">
            <div className="flex flex-wrap">
              <div className="xl:grow" />
              <div className="xl:w-[53%]">
                <RichTextField data={prismicArticle.data.body} className="prose prose-custom" />
              </div>
            </div>
          </FadeInOpacity>
        )}
        {/* begin Slices */}
        {prismicArticle.data?.body1 && prismicArticle.data.body1.map((slice, i) => {

          // Safeguard against slice types that we aren't querying for.
          if (!slice.id) return null;

          // Convert id to class.
          const id = `slice-${slice.id.toString().substring(0,5)}-${i}`;

          // For image___text slice; can't declare it inside of case clause.
          let imageAlignClass = 'image-left';

          return (
            <FadeInOpacity
              key={slice.id.toString()}
              id={id}
            >
              {(() => {
                switch (slice.slice_type) {
                  case 'rich_text':
                    return (
                      <div className="max-w-8xl mb-16">
                        <div className="flex flex-wrap">
                          <div className="xl:grow" />
                          <div className="xl:w-[53%]">
                            <RichTextField data={slice.primary.text} className="prose prose-custom" id={id} />
                          </div>
                        </div>
                      </div>
                    );
                  case 'blockquote':
                    return (
                      <div className="max-w-8xl mx-auto mb-16">
                        <Blockquote slice={slice} colour={prismicArticle.fields?.colour} />
                      </div>
                    );
                  case 'image_grid':
                    return (
                      <div className="mx-auto max-w-8xl mb-16 flex">
                        <div className={`image-grid image-grid-${slice.items.length} inline-grid items-start gap-4 my-6 2xl:my-16 mx-auto`}>
                          {slice.items.map((item, j) => {
                            return (
                              <React.Fragment key={`${item.id}-grid-${j}`}>
                                {item.caption?.html ? (
                                  <figure className="max-w-[800px] mx-auto">
                                    <GatsbyImage
                                      image={item.image.gatsbyImageData}
                                      alt={item.image?.alt || ''}
                                      objectFit="contain"
                                    />
                                    {caption
                                      && (
                                      <figcaption
                                        className="italic xl:mx-4"
                                        dangerouslySetInnerHTML={{ __html: item.caption.html }}
                                      />
                                    )}
                                  </figure>
                                ): (
                                  <div className="max-w-[800px] mx-auto">
                                    <GatsbyImage
                                      image={item.image.gatsbyImageData}
                                      alt={item.image?.alt || ''}
                                      objectFit="contain"
                                    />
                                  </div>
                                )}
                              </React.Fragment>
                            );
                          })}
                        </div>
                      </div>
                    );
                  case 'image___text':
                    return (
                      <div className="mx-auto max-w-8xl flex mb-16">
                        <Switcher className="gap-12">
                          <div className={`${ slice.primary.alignment === 'Align image left' ? ' md:order-2 sm:order-none' : '' }`}>
                            <RichTextField data={slice.primary.text} className="prose prose-custom" />
                          </div>
                          <div className={`flex${ slice.primary.alignment === 'Align image left' ? ' md:order-1 sm:order-none' : '' }`}>
                            <figure className="max-w-[800px] mx-auto">
                              <GatsbyImage
                                image={slice.primary.image.gatsbyImageData}
                                alt=""
                                objectFit="contain"
                              />
                            </figure>
                          </div>
                        </Switcher>
                      </div>
                    );

                  case 'info_boxes':
                    return (
                      <div className="mx-auto max-w-8xl flex justify-center mb-16">
                        <InfoBoxes slice={slice} colour={prismicArticle.fields?.colour} />
                      </div>
                    );

                  default:
                    return '';
                }
              })()}
            </FadeInOpacity>
          )
        })}
        {/* end Slices */}
        {/* Related content */}
        {prismicArticle.data.related.length > 0 && (
          <FadeInOpacity className="max-w-8xl pb-12">
            <div className="flex flex-wrap">
              <div className="xl:grow" />
              <div className="xl:w-[53%]">
                <ViewRelatedNews data={prismicArticle.data.related} />
              </div>
            </div>
          </FadeInOpacity>
        )}
      </Section>
    </div>
  );
};

Post.propTypes = {
  data: PropTypes.shape({
    prismicArticle: PropTypes.shape({
      uid: PropTypes.string,
      data: PropTypes.shape({
        body1: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.string,
            slice_type: PropTypes.string,
            primary: PropTypes.shape({
              alignment: PropTypes.string,
              image: PropTypes.shape({
                gatsbyImageData: PropTypes.object,
                alt: PropTypes.string,
              }),
              text: PropTypes.shape({
                html: PropTypes.string,
              }),
            }),
            items: PropTypes.arrayOf(
              PropTypes.shape({
                image: PropTypes.shape({
                  gatsbyImageData: PropTypes.object,
                  alt: PropTypes.string,
                }),
              }),
            ),
          }),
        ),
      }),
    }),
  }),
};

export default withPrismicPreview(Post);

export const pageQuery = graphql`
  query ($uid: String!) {
    site {
      siteMetadata {
        title
        description
        author
        siteUrl
      }
    }
    prismicArticle(
      uid: {
        eq: $uid
      }
    ) {
      _previewable
      first_publication_date(formatString: "MMMM D, YYYY")
      uid
      fields {
        colour
      }
      data {
        date(formatString: "MMMM D, YYYY")
        title {
          text
        }
        body {
          html
          richText
        }
        teaser {
          html
          text
          richText
        }
        main_image {
          url
          alt
          gatsbyImageData(
            width: 1200
          )
          thumbnails {
            OpenGraph {
              alt
              url(
                imgixParams: {
                  q: 100
                }
              )
            }
            Portrait {
              alt
              url
              gatsbyImageData(
                width: 900
              )
            }
          }
        }
        featured_image {
          url (
            imgixParams: {
              rect: "0,0,0,0",
              h: 1.0,
              w: 800
            }
          )
          alt
        }
        related {
          related_content {
            document {
              ... on PrismicArticle {
                uid
                data {
                  teaser {
                    richText
                  }
                  title {
                    text
                  }
                  main_image {
                    alt
                    gatsbyImageData(
                      width: 300
                    )
                    thumbnails {
                      Square {
                        alt
                        gatsbyImageData(
                          width: 300
                        )
                      }
                    }
                  }
                }
              }
            }
          }
        }
        body1 {
          __typename
          ...on PrismicArticleDataBody1RichText {
            id
            slice_type
            primary {
              text {
                html
                richText
              }
            }
          }
          ...on PrismicArticleDataBody1Blockquote {
            id
            slice_type
            primary {
              text {
                html
                richText
              }
            }
          }
          ...on PrismicArticleDataBody1ImageText {
            id
            slice_type
            primary {
              image {
                url
                alt
                gatsbyImageData(
                  width: 960
                  imgixParams: {
                    rect: "0,0,0,0",
                  }
                )
              }
              text {
                html
                richText
              }
              alignment
            }
          }
          ...on PrismicArticleDataBody1ImageGrid {
            id
            slice_type
            items {
              image {
                url
                alt
                gatsbyImageData(
                  width: 960
                  imgixParams: {
                    rect: "0,0,0,0",
                  }
                )
              }
            }
          }
          ...on PrismicArticleDataBody1InfoBoxes {
            id
            slice_type
            items {
              datapoint
              summary {
                html
                richText
              }
              footnote {
                html
                richText
              }
            }
            primary {
              image {
                alt
                thumbnails {
                  Landscape {
                    gatsbyImageData(
                      width: 800
                    )
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
