import React from 'react';
import { PrismicPreviewProvider } from 'gatsby-plugin-prismic-previews';
import { repositoryConfigs } from './prismicPreviews';

// Adds a shared React Context for Prismic components and preview sessions.
export const wrapRootElement = ({ element }) => (
  <PrismicPreviewProvider repositoryConfigs={repositoryConfigs}>
    {element}
  </PrismicPreviewProvider>
);
