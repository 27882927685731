import React, { Fragment } from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Dialog, Transition } from '@headlessui/react';

import {
  Stack,
  Switcher
} from '../EveryLayout';

const Modal = ({ open, handleSetOpen, title, image, children, className }) => {

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="modal relative z-50" onClose={() => handleSetOpen(false)}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-900 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="modal-panel-wrapper fixed inset-0 z-10 overflow-y-auto">
          <div className="p-4 flex items-center justify-center min-h-[100%]">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className={`relative bg-off-white rounded-sm text-left overflow-hidden shadow-xl transform transition-all lg:mx-0 ${className ? className : ''}`}>
                <Switcher className="gap-0">
                  {image && (
                    <div className="hidden md:block">
                      <GatsbyImage
                        style={{
                          gridArea: "1/1",
                        }}
                        layout="fullWidth"
                        alt=""
                        image={
                          image.childImageSharp.gatsbyImageData
                        }
                        className="h-full object-cover"
                      />
                    </div>
                  )}
                  <div>
                    <div className="modal-button-wrapper absolute top-0 right-0 z-10 bg-transparent pt-2 pr-2 sm:pt-4 sm:pr-4">
                      <button
                        type="button"
                        className="bg-transparent text-black"
                        onClick={() => handleSetOpen(false)}
                      >
                        <span className="sr-only">Close</span>
                        <svg className="h-12 w-12 sm:w-10 sm:h-10 bg-transparent" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1" stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                      </button>
                    </div>
                    <div className={`px-6 pb-12 pt-16 sm:pt-12 md:px-12 h-full ${image ? ' flex items-center' : ''}`}>
                      <Stack className="modal-content">
                        {title
                          && (
                          <div className="prose prose-custom">
                            <Dialog.Title>
                              {title}
                            </Dialog.Title>
                          </div>
                        )}
                        <Dialog.Description as="div">
                          {children}
                        </Dialog.Description>
                      </Stack>
                    </div>
                  </div>
                </Switcher>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export { Modal };
