import React from 'react';
import { Link } from 'gatsby';

import { ListHoverItem } from '../Page';

const PrismicSliceJumpLinks = ({ slice }) => {
  return (
    <div className="w-full max-w-[800px] mx-auto">
      <div className="mb-12">
        <div className="prose prose-custom">
          <h3 className="text-lg mb-8">In this Page:</h3>
        </div>
        <div className="divide-y divide-color-base divide-opacity-20 border-y border-color-base border-opacity-20 sm:mr-12 2xl:mr-24">
          {slice.items.map((item, i) => {
            return (
              <Link
                key={`pagemenu-${i}`}
                to={`#${item.link_target}`}
                className="hover-row block overflow-hidden py-2 no-underline text-sm xs:text-[1.75rem]"
              >
                <ListHoverItem>
                  {item.label}
                </ListHoverItem>
              </Link>
            )
          })}
        </div>
      </div>
    </div>
  )
};

export default PrismicSliceJumpLinks;
