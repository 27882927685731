import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

const LogoMonogramWhite = (props) => {

  const data = useStaticQuery(graphql`
    query {
      file(name: {eq: "_RGB_AblerNordic_Monogram-White"}) {
        publicURL
      }
    }
  `);

  const attrs = {
    className: props.className || '',
    width: props.width || '',
    height: props.height || '',
  };

  if (data.file?.publicURL) {
    return (
      <img
        src={data.file.publicURL}
        {...attrs}
      />
    );
  }

  return <div {...attrs} />;
};

export default LogoMonogramWhite;
