import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import { ListHoverItem } from '../';

import { menu } from '../../../data';
import { usePrismicPage } from '../../../hooks';

const Submenu = ({ section }) => {
  const { pages } = usePrismicPage();

  let sectionPage = [];
  if (section) {
    sectionPage = menu.filter(item => item.prismicId === section.prismicId);
  }
  if (sectionPage.length > 0) {
    if (sectionPage[0].children?.length > 0) {
      return (
        <div>
          <div className="prose prose-custom">
            <h3 className="text-lg mb-8">In this Section:</h3>
          </div>
          <div className="divide-y divide-color-base divide-opacity-20 border-y border-color-base border-opacity-20 sm:mr-12 2xl:mr-24">
            {sectionPage[0].children.map((item, i) => {
              let label = item.label;
              if (item.prismicId) {
                let childPage = pages.filter(page => page.node.prismicId === item.prismicId);
                if (childPage.length > 0) {
                  label = childPage[0].node.data.title.text;
                }
              }
              return (
                <Link
                  key={`submenu-${i}`}
                  to={`${item.path}/`}
                  className="hover-row block overflow-hidden py-2 no-underline text-sm xs:text-[1.75rem]"
                >
                  <ListHoverItem>
                    {label}
                  </ListHoverItem>
                </Link>
              )
            })}
          </div>
        </div>
      )
    }
  }
  return null;
};

export default Submenu;
