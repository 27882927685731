import React from 'react';

import './rich-text.css';

const PrismicSliceRichText = ({ slice }) => {

  const style = slice.primary?.style.replace(/\s+/g, '-').toLowerCase() || '';

  return (
    <div
      className={`rich-text ${style}${style === 'style-1' ? ' bg-gray-50 p-6 pb-8' : ''}`}
    >
      <div
        className="prose prose-custom mx-auto"
        dangerouslySetInnerHTML={{ __html: slice.primary.body.html }}
      />
    </div>
  );
};

export default PrismicSliceRichText;
