/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable react/prop-types */

/**
 * Disabling media-has-caption for this file - videos have no audio
 */

import React, { useEffect, useState } from 'react'

const Video = ({
  media,
  videos,
  poster,
  canPlay,
  playing,
  togglePlay,
}) => {

  const [prefersReducedMotion, setPrefersReducedMotion] = useState(false);

  useEffect(() => {
    // We need this since window isn't available during builds.
    if (typeof window === 'undefined') return;

    // Get prefers-reduced-motion.
    const matchMedia = window.matchMedia('(prefers-reduced-motion: reduce)');

    setPrefersReducedMotion(matchMedia.matches);

    matchMedia.addEventListener('change', (e) => {
      setPrefersReducedMotion(e.matches);
    });
  }, []);

  return (
    <>
      {!prefersReducedMotion
        && (
        <video
          ref={media}
          poster={poster}
          onClick={() => togglePlay(playing)}
          muted
          loop
          autoPlay
          playsInline
          className="z-0"
        >
          {videos.map((video) => (
            <source
              key={video.key}
              src={video.src}
              type={video.type}
            />
          ))}
          <img src={poster} alt="The smiling faces of two entrepreneurs (screen capture from Abler Nordic video)." loading="lazy" className="object-cover h-full sm:h-auto" />
        </video>
      )}
      {prefersReducedMotion
        && (
        <video
          ref={media}
          poster={poster}
          onClick={() => togglePlay(playing)}
          muted
          loop
          playsInline
          preload="none"
          className="z-0"
        >
          {videos.map((video) => (
            <source
              key={video.key}
              src={video.src}
              type={video.type}
            />
          ))};
          <img src={poster} alt="The smiling faces of two entrepreneurs (screen capture from Abler Nordic video)." loading="lazy" className="object-cover h-full sm:h-auto" />
        </video>
      )}
    </>
  );
}
export default Video;
