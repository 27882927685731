import React from 'react';
import { Link } from 'gatsby';

import { LogoMonogramBlack } from '../Brand';
import {
  Cluster,
  Stack,
  Switcher
} from '../EveryLayout';
import { Section } from '../Layout';
import {
  Button,
  SocialPages
} from '../Page';
import { NewsletterSignup } from '../Forms';

import { menu } from '../../data';

const Footer = () => (
  <div className="bg-nordic-blue-500 pt-8 pb-16 2xl:pt-24 2xl:pb-32">
    <Section>
      <Stack>
        <Switcher className="gap-8 2xl:gap-16">
          <div className="py-4">
            <LogoMonogramBlack />
          </div>
          <div className="grow-[1.5]" />
        </Switcher>
        <Switcher className="gap-8 2xl:gap-16">
          <Stack>
            <div>
              {/* 21px, lh 25.84px - all serif text in this component */}
              <span className="block text-[1.3rem] leading-[1.25] mb-4 max-w-prose">
                Abler Nordic was established in 2008 in Oslo, Norway as a partnership between the public sector and private investors. Abler Nordic invests in institutions building financial inclusion in Africa and Asia.
              </span>
            </div>
            <div className="text-[1.3rem]">
              <span className="inline-block font-trade leading-[1.2] mb-2">Offices</span>
              <span className="block leading-[1.25] mb-4 max-w-prose">
                Abler Nordic has offices in Norway, Denmark, Kenya, India, and Indonesia. View our <Link to="/contact/">Contact</Link> page for details.
              </span>
            </div>
          </Stack>
          <div className="grow-[1.5]">
            <Cluster className="gap-8 items-start lg:justify-between">
              <div className="w-1/2 lg:w-auto">
                {/* 21px, lh 25px - all headers in this component */}
                <span className="inline-block font-trade text-[1.3rem] leading-[1.2] mb-2">Menu</span>
                <nav>
                  <ul>
                    {menu.map((item, i) => (
                      <li
                        key={`footer-1-${i}`}
                      >
                        <Link
                          to={item.path}
                          className="text-[1.3rem] leading-[1.25] underline underline-offset-2"
                        >
                          {item.label}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </nav>
              </div>
              <div>
                <span className="inline-block font-trade text-[1.3rem] leading-[1.2] mb-2">Follow</span>
                <SocialPages />
              </div>
              <div className="w-full lg:w-1/2">
                <div className="text-center sm:text-left">
                  <span className="block text-[1.3rem] leading-[1.25] mb-4 max-w-prose">
                    Subscribe to our newsletter for news and updates!
                  </span>
                  <NewsletterSignup>
                    <Button>Subscribe</Button>
                  </NewsletterSignup>
                </div>
              </div>
            </Cluster>
          </div>
        </Switcher>
        <Cluster className="justify-center sm:justify-start mt-8 text-center sm:text-left text-[1.3rem] leading-[1.25]">
          <div>
            <span className="block mb-8">
              <Link to="/complaints/">Complaints</Link>
            </span>
            <span className="block">
              &copy; 2019-{new Date().getFullYear()} All rights reserved. <span className="block sm:inline-block"><Link to="/privacy-policy/">Privacy Policy</Link> | <Link to="/cookie-policy/">Cookie Policy</Link></span>
            </span>
          </div>
          <div>
            {/*
            <Cluster as="ul">
              <li>
                <Link
                  to="#"
                  className="text-[1.3rem] leading-[1.25]"
                >
                  Privacy Policy
                </Link>
              </li>
              <li>
                <Link
                  to="#"
                  className="text-[1.3rem] leading-[1.25]"
                >
                  Terms
                </Link>
              </li>
            </Cluster>
            */}
          </div>
        </Cluster>
      </Stack>
    </Section>
  </div>
);

export default Footer;
