import React from 'react';
import {
  useStaticQuery,
  graphql
} from 'gatsby';

const usePrismicPage = () => {
  const { allPrismicPage } = useStaticQuery(
    graphql`
      query {
        allPrismicPage {
          edges {
            node {
              uid
              data {
                title {
                  text
                }
              }
              prismicId
            }
          }
        }
      }
    `
  );

  return {
    pages: allPrismicPage.edges,
  };
};

export default usePrismicPage;