import React from 'react';
import PropTypes from 'prop-types';
import { ArrowRightIcon } from '@heroicons/react/24/solid';

import './listHoverItem.css';

/**
 * This component requires a parent element with .hover-row class. Fix this
 * later!
 */

const ListHoverItem = ({ children }) => (
  <span className="anim">
    <ArrowRightIcon className="h-4 w-4" aria-hidden="true" />
    <span className="cursor-pointer inline-block">
      {children}
    </span>
  </span>
);

ListHoverItem.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ListHoverItem;
