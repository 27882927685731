/**
 * This file contains configuration for `gatsby-plugin-prismic-previews` to
 * support preview sessions from Prismic with unpublished documents.
 *
 * @see https://prismic.io/docs/technical-reference/gatsby-plugin-prismic-previews
 */
import { linkResolver } from './utils/linkResolver';

import ArticleTemplate from './templates/post';
import StoryTemplate from './templates/story';


export const repositoryConfigs = [
  {
    repositoryName: 'nmimicro',
    linkResolver,
    componentResolver: {
      article: ArticleTemplate,
      story: StoryTemplate,
    },
  },
];